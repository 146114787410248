import { OrderSide } from '@derivadex/types';
import { convertTickSize } from '@derivadex/utils';
import Input from 'components/Input/Input';
import { useLeverageUpdate } from 'hooks/useLeverageUpdate';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSelectedMarket } from 'store/market/selectors';
import { getSelectedStrategyId } from 'store/strategy/selectors';

import { Intent } from './OrderForm';

export default function AmountInput({
    orderSide,
    setUserAlreadyTouched,
    setLeverageState,
    setLeverage,
    price,
    amount,
    setAmount,
    maxLeverage,
    isModifyOrder,
}: {
    orderSide: OrderSide;
    setUserAlreadyTouched: Dispatch<SetStateAction<boolean>>;
    setLeverageState: Dispatch<SetStateAction<Intent | null>>;
    setLeverage: Dispatch<SetStateAction<number>>;
    price: string;
    amount: string;
    setAmount: Dispatch<SetStateAction<string>>;
    maxLeverage: number;
    isModifyOrder: boolean;
}) {
    const { t } = useTranslation();

    const currentStrategyId = useSelector(getSelectedStrategyId);
    const market = useSelector(getSelectedMarket);
    const [triggerUpdate, setTriggerUpdate] = useState<boolean>(false);

    useLeverageUpdate(
        orderSide,
        setLeverageState,
        setLeverage,
        price,
        amount,
        maxLeverage,
        triggerUpdate,
        setTriggerUpdate,
    );

    const inputEnforcer = (nextUserInput: string, type: string) => {
        if (market === undefined || currentStrategyId === undefined) return;
        const amountTickSize = convertTickSize(market.minOrderSize.toNumber());
        const amountInputRegex = RegExp(`^\\d*(?:\\\\[.]\\d{0,${amountTickSize}})?$`);
        setUserAlreadyTouched(true);
        function escapeRegExp(string: string): string {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        }

        const regexType = amountInputRegex;
        if (nextUserInput === '' || regexType.test(escapeRegExp(nextUserInput))) {
            if (type === 'amount') {
                setAmount(nextUserInput);
                setTriggerUpdate(true);
            }
        }
    };

    function getCryptoIcon(symbol: string) {
        return symbol.substring(0, symbol.length - 1);
    }

    return (
        <Input
            id="amount"
            cy={isModifyOrder ? 'modifyAmount' : 'amount'}
            tooltip={t('theAmountOf')}
            title={t('limitAmount')}
            inputEnforcer={inputEnforcer}
            badge={getCryptoIcon(market?.symbol || '')}
            value={amount}
        />
    );
}
