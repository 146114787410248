import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    List,
    ListIcon,
    ListItem,
} from '@chakra-ui/react';
import { AccountEventType } from '@derivadex/types';
import { getFrontendLogger } from '@derivadex/utils';
import ExplorerButton from 'components/Marketplace/OrderTables/ExplorerButton';
import { format } from 'date-fns';
import { useScreenSize } from 'hooks/useScreenSize';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { getAlertNotificationList, getAlertNotificationUnreadCount, isAlertsDialogActive } from 'store/ui/selectors';
import {
    ALERT_NOTIFICATIONS_CLEAR_ALL,
    SET_ALERT_NOTIFICATIONS_AS_ALL_READ,
    TOGGLE_ALERTS_DIALOG,
} from 'store/ui/slice';
import { getTwentyOneByteEthAddress } from 'store/web3/selectors';

export default function AlertsDropdown() {
    const { t } = useTranslation();
    const isActive = useSelector(isAlertsDialogActive);
    const alerts = useSelector(getAlertNotificationList);
    const traderAddress = useSelector(getTwentyOneByteEthAddress);
    const dispatch = useDispatch();
    const isDesktopView = useScreenSize();
    const alertsUnreadCount = useSelector(getAlertNotificationUnreadCount);

    return (
        <Drawer
            isOpen={isActive}
            placement={isDesktopView ? 'right' : 'bottom'}
            onClose={() => dispatch(TOGGLE_ALERTS_DIALOG())}
            size="sm"
        >
            <DrawerOverlay>
                <DrawerContent bgColor="card.100" id="drawer">
                    <DrawerCloseButton />
                    <DrawerHeader>{t('notifications')}</DrawerHeader>
                    <DrawerBody>
                        <List spacing={5} p={'1rem'}>
                            {alerts.length > 0 ? (
                                alerts.map((it) => {
                                    getFrontendLogger().log(it);
                                    return (
                                        <ListItem key={it.c.epoch + it.c.txOrdinal}>
                                            <ListIcon
                                                as={FaCheckCircle}
                                                color={it.read ? 'gray.400' : 'brand.green.400'}
                                            />
                                            {`${format(it.c.createdAt, 'MM/dd/yy, pp')} - `}
                                            {`${
                                                it.t === AccountEventType.LIQUIDATION
                                                    ? 'Liquidation event'
                                                    : it.t === AccountEventType.PNL_REALIZATION
                                                    ? 'PnL settlement'
                                                    : 'Liquidation price alert for position ' + it.c.symbol
                                            }. `}
                                            {[
                                                AccountEventType.LIQUIDATION.toString(),
                                                AccountEventType.PNL_REALIZATION.toString(),
                                            ].includes(it.t) ? (
                                                <ExplorerButton
                                                    label={
                                                        it.t === AccountEventType.LIQUIDATION
                                                            ? t('liquidationExplorerButtonDescription')
                                                            : t('pnlRealizationExplorerButtonDescription')
                                                    }
                                                    url={`epoch/${it.c.epoch}/txOrdinal/${it.c.txOrdinal}`}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </ListItem>
                                    );
                                })
                            ) : (
                                <ListItem key={0}>There are no notifications currently.</ListItem>
                            )}
                        </List>
                        <Box p={'1rem'}>
                            <Button
                                isDisabled={alertsUnreadCount === 0}
                                mr={'1rem'}
                                onClick={() => {
                                    dispatch(SET_ALERT_NOTIFICATIONS_AS_ALL_READ(traderAddress!));
                                }}
                            >
                                Mark all as read
                            </Button>
                            <Button
                                isDisabled={alerts.length === 0}
                                onClick={() => {
                                    dispatch(ALERT_NOTIFICATIONS_CLEAR_ALL(traderAddress!));
                                    dispatch(TOGGLE_ALERTS_DIALOG());
                                }}
                            >
                                Clear all
                            </Button>
                        </Box>
                    </DrawerBody>
                </DrawerContent>
            </DrawerOverlay>
        </Drawer>
    );
}
