import { ActionType, FundingRateComparisonUIData } from '@derivadex/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchFundingComparisonDataParams } from 'store/requestUtils';
import { createAction } from 'typesafe-actions';

export type FundingComparisonState = {
    fundingComparisonData: FundingRateComparisonUIData[];
};

export const initialFundingComparisonState: FundingComparisonState = {
    fundingComparisonData: [],
};

export const fundingComparisonSlice = createSlice({
    name: 'fundingComparison',
    initialState: initialFundingComparisonState,
    reducers: {
        SET_FUNDING_COMPARISON_DATA: (
            state,
            action: PayloadAction<{
                fundingComparisonResponse: FundingRateComparisonUIData[];
            }>,
        ) => {
            state.fundingComparisonData = action.payload.fundingComparisonResponse;
        },
    },
});

export const { SET_FUNDING_COMPARISON_DATA } = fundingComparisonSlice.actions;

export const FETCH_FUNDING_DATA = createAction(ActionType.FETCH_FUNDING_COMPARISON)<FetchFundingComparisonDataParams>();
