import { BigNumber } from '@0x/utils';
import * as wasmModule from '@derivadex/ddx-wasm';
import {
    CancelOrderIntent,
    Context,
    ModifyOrderIntent,
    OneClickTradingStorageData,
    OrderIntent,
    OrderRejectReason,
    RequestFailure,
    RequestSequenced,
    RequestTransacted,
    RequestType,
    ResponseApi,
} from '@derivadex/types';
import {
    createModifyOrderIntentTypedData,
    createOrderIntentTypedData,
    DerivadexSocket,
    getErrorMessage,
    getFrontendLogger,
    signTypedDataForEthersWithPrivateKey,
    transformTypedDataForEthers,
} from '@derivadex/utils';
import { Buffer } from 'buffer/';
import { randomBytes } from 'crypto';
import { utils } from 'ethers';
import { OPERATOR_DECIMAL_MULTIPLIER } from 'utils/constants';
import { signTypedMessage } from 'utils/web3_api';

export function getRejectionReasonMessageId(
    isFullyRejected: boolean,
    amount: BigNumber,
    reason: OrderRejectReason,
): string {
    let messageId = '';
    switch (reason) {
        case OrderRejectReason.MarketOrderNotFullyFilled:
            messageId = 'orderRejectMarketNotFilled';
            break;
        case OrderRejectReason.SelfMatch:
            if (isFullyRejected) {
                messageId = 'orderRejectSelfMatchFully';
            } else {
                messageId = 'orderRejectSelfMatchPartially';
            }
            break;
        case OrderRejectReason.Solvency:
            if (isFullyRejected) {
                messageId = 'orderRejectSolvencyFully';
            } else {
                messageId = 'orderRejectSolvencyPartially';
            }
            break;
        case OrderRejectReason.PostOnlyViolation:
            if (isFullyRejected) {
                messageId = 'orderRejectPostOnlyFully';
            } else {
                messageId = 'orderRejectPostOnlyPartially';
            }
            break;
        default:
            break;
    }
    return messageId;
}

export async function signOrderIntent(
    orderIntent: OrderIntent,
    context: Context,
    oneClickTradingData: OneClickTradingStorageData | undefined,
): Promise<string> {
    const scaledOrderIntent: OrderIntent = {
        ...orderIntent,
        amount: orderIntent.amount.multipliedBy(OPERATOR_DECIMAL_MULTIPLIER),
        price: orderIntent.price.multipliedBy(OPERATOR_DECIMAL_MULTIPLIER),
        stopPrice: orderIntent.stopPrice.multipliedBy(OPERATOR_DECIMAL_MULTIPLIER),
    };
    const scaledOrderIntentTyped = createOrderIntentTypedData(
        scaledOrderIntent,
        context.chainId,
        context.deployment,
        context.contractAddresses.derivaDEXAddress,
    );
    const typedDataForEthers = transformTypedDataForEthers(scaledOrderIntentTyped);
    if (oneClickTradingData && oneClickTradingData.enabled) {
        return await signTypedDataForEthersWithPrivateKey(typedDataForEthers, oneClickTradingData.sessionPrivateKey);
    } else {
        return await signTypedMessage(typedDataForEthers);
    }
}

export async function signModifyOrderIntent(
    modifyOrderIntent: ModifyOrderIntent,
    context: Context,
    oneClickTradingData: OneClickTradingStorageData | undefined,
): Promise<string> {
    const scaledOrderIntent: ModifyOrderIntent = {
        ...modifyOrderIntent,
        amount: modifyOrderIntent.amount.multipliedBy(OPERATOR_DECIMAL_MULTIPLIER),
        price: modifyOrderIntent.price.multipliedBy(OPERATOR_DECIMAL_MULTIPLIER),
        stopPrice: modifyOrderIntent.stopPrice.multipliedBy(OPERATOR_DECIMAL_MULTIPLIER),
    };
    try {
        const scaledOrderIntentTyped = createModifyOrderIntentTypedData(
            scaledOrderIntent,
            context.chainId,
            context.deployment,
            context.contractAddresses.derivaDEXAddress,
        );
        const typedDataForEthers = transformTypedDataForEthers(scaledOrderIntentTyped);
        if (oneClickTradingData && oneClickTradingData.enabled) {
            return await signTypedDataForEthersWithPrivateKey(
                typedDataForEthers,
                oneClickTradingData.sessionPrivateKey,
            );
        } else {
            return await signTypedMessage(typedDataForEthers);
        }
    } catch (e) {
        getFrontendLogger().log('signModifyOrderIntent error', e, getErrorMessage(e));
    }
    return '';
}

export async function submitOrderIntent(
    orderIntent: OrderIntent,
    traderAddress: string,
    socket: DerivadexSocket,
    encryptionKey: string,
    oneClickTradingData: OneClickTradingStorageData | undefined,
): Promise<ResponseApi<RequestSequenced | RequestTransacted | RequestFailure>> {
    const intent = { t: 'Order', c: orderIntent };
    const encryptedIntent = await encryptIntentHelper(intent, encryptionKey, oneClickTradingData);
    return await socket.submitIntent({
        t: RequestType.ORDER_INTENT,
        c: {
            traderAddress,
            encryptedIntent,
        },
    });
}

export async function submitModifyOrderIntent(
    orderIntent: ModifyOrderIntent,
    traderAddress: string,
    socket: DerivadexSocket,
    encryptionKey: string,
    oneClickTradingData: OneClickTradingStorageData | undefined,
): Promise<ResponseApi<RequestSequenced | RequestTransacted | RequestFailure>> {
    const intent = { t: 'ModifyOrder', c: orderIntent };
    const encryptedIntent = await encryptIntentHelper(intent, encryptionKey, oneClickTradingData);
    return await socket.submitIntent({
        t: RequestType.MODIFY_INTENT,
        c: {
            traderAddress,
            encryptedIntent,
        },
    });
}

export async function encryptIntentHelper(
    intent: {
        t: string;
        c: OrderIntent | CancelOrderIntent | ModifyOrderIntent;
    },
    encryptionKey: string,
    oneClickTradingData: OneClickTradingStorageData | undefined,
): Promise<string> {
    if (oneClickTradingData && oneClickTradingData.enabled) {
        intent.c.sessionKeySignature = oneClickTradingData.signature;
    } else {
        intent.c.sessionKeySignature = null;
    }
    return encryptIntent(encryptionKey, intent) as string;
}

export function mergeOpenOrdersAfterUpdate(
    currentOpenOrders: OrderIntent[],
    openOrdersFromUpdate: OrderIntent[],
    twentyOneEthAddress: string,
): { isMerged: boolean; result: OrderIntent[] } {
    let isMerged = false;
    const openOrdersMerged: OrderIntent[] = [];
    for (const order of currentOpenOrders) {
        const incomingOrder = openOrdersFromUpdate.find((it) => it.orderHash === order.orderHash);
        if (incomingOrder === undefined) {
            isMerged = true;
            openOrdersMerged.push(order);
        } else if (incomingOrder.traderAddress.toLowerCase() === twentyOneEthAddress.toLowerCase()) {
            isMerged = true;
            openOrdersFromUpdate = openOrdersFromUpdate.filter((it) => it.orderHash !== incomingOrder.orderHash);
            if (incomingOrder.remainingAmount.gt(0)) {
                openOrdersMerged.push(incomingOrder);
            }
        } else {
            getFrontendLogger().logError(
                `Unexpected Open Order from different trader: ${order.traderAddress} != ${twentyOneEthAddress}`,
            );
        }
    }
    for (const incomingOrderRemaining of openOrdersFromUpdate) {
        isMerged = true;
        openOrdersMerged.push(incomingOrderRemaining);
    }
    return { isMerged, result: openOrdersMerged };
}

/***
 * JSON encode then encrypt
 */
export const encryptIntent = (encryptionKey: string, payload: any, isKycRequest?: boolean) => {
    try {
        // Create an ephemeral ECDSA private key to encrypt the request.
        const secretKeyBytes = new Uint8Array(randomBytes(32));

        // Unique single-use nonce for each encryption.
        // It is important to never repeat nonces.
        const nonceBytes = new Uint8Array(randomBytes(12));

        const json = JSON.stringify(payload);
        const buffer = Buffer.from(json);
        // We use native Uint8Array where possible to avoid unnecessary string operations.
        const requestBytes = new Uint8Array(buffer);

        const encryptedBytes = wasmModule.encrypt(requestBytes, secretKeyBytes, encryptionKey, nonceBytes);
        if (isKycRequest) {
            return encryptedBytes;
        }

        return utils.hexlify(encryptedBytes);
    } catch (error: any) {
        throw new Error(`System malfunction: Could not encrypt intent. ${getErrorMessage(error)}.`);
    }
};
