import { OrderSide, OrderType } from '@derivadex/types';
import { convertTickSize, getFrontendLogger } from '@derivadex/utils';
import Input from 'components/Input/Input';
import { useLeverageUpdate } from 'hooks/useLeverageUpdate';
import useOrderFormMarkPrice from 'hooks/useOrderFormMarkPrice';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSelectedMarket } from 'store/market/selectors';
import { getSelectedStrategyId } from 'store/strategy/selectors';
import { getOrderbookPriceSelected } from 'store/ui/selectors';
import { getUSDCCollateral } from 'store/web3/selectors';

import { Intent } from './OrderForm';

export default function PriceInput({
    orderSide,
    orderType,
    userAlreadyTouched,
    setUserAlreadyTouched,
    setLeverageState,
    setLeverage,
    price,
    setPrice,
    amount,
    maxLeverage,
    isModifyOrder,
}: {
    orderSide: OrderSide;
    orderType: OrderType;
    userAlreadyTouched: boolean;
    setUserAlreadyTouched: Dispatch<SetStateAction<boolean>>;
    setLeverageState: Dispatch<SetStateAction<Intent | null>>;
    setLeverage: Dispatch<SetStateAction<number>>;
    price: string;
    setPrice: Dispatch<SetStateAction<string>>;
    amount: string;
    maxLeverage: number;
    isModifyOrder: boolean;
}) {
    const { t } = useTranslation();

    const currentStrategyId = useSelector(getSelectedStrategyId);
    const market = useSelector(getSelectedMarket);
    // If user clicks on orderbook price, the price in place order form must be updated with that value
    const orderbookPriceSelected = useSelector(getOrderbookPriceSelected);
    const usdcCollateral = useSelector(getUSDCCollateral);
    const [triggerUpdate, setTriggerUpdate] = useState<boolean>(false);
    useOrderFormMarkPrice(userAlreadyTouched, setPrice);
    useLeverageUpdate(
        orderSide,
        setLeverageState,
        setLeverage,
        price,
        amount,
        maxLeverage,
        triggerUpdate,
        setTriggerUpdate,
    );
    getFrontendLogger().log('refreshing price input');

    useEffect(() => {
        if (orderbookPriceSelected) {
            setUserAlreadyTouched(true);
            setPrice(orderbookPriceSelected.toString());
            getFrontendLogger().log('price input refresh');
        }
    }, [orderbookPriceSelected]);

    const inputEnforcer = (nextUserInput: string, type: string) => {
        if (market === undefined || currentStrategyId === undefined) return;
        const priceTickSize = convertTickSize(market.tickSize.toNumber());
        const priceInputRegex =
            priceTickSize === 0 ? RegExp('^\\d+$') : RegExp(`^\\d*(?:\\\\[.]\\d{0,${priceTickSize}})?$`);
        setUserAlreadyTouched(true);
        function escapeRegExp(string: string): string {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        }

        const regexType = priceInputRegex;
        if (nextUserInput === '' || regexType.test(escapeRegExp(nextUserInput))) {
            if (type === 'price') {
                setPrice(nextUserInput);
                setTriggerUpdate(true);
            }
        }
    };

    return orderType === OrderType.Limit ? (
        <>
            <Input
                id="price"
                cy={isModifyOrder ? 'modifyPrice' : 'price'}
                tooltip={t('orderWillOnly')}
                title={t('limitPrice')}
                inputEnforcer={inputEnforcer}
                badge={usdcCollateral?.token.symbol.toUpperCase() || ''}
                value={price}
            />
        </>
    ) : (
        <></>
    );
}
