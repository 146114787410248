import { BigNumber } from '@0x/utils';
import {
    AccountEventType,
    AccountMessage,
    ActionType,
    Context,
    FeedEventType,
    LiquidationEvent,
    Market,
    ModifyOrderIntent,
    OneClickTradingStorageData,
    OperatorConfig,
    OrderIntent,
    OrderIntentEvent,
    OrderReject,
    OrderRejectEvent,
    OrderSide,
    OrderType,
    PaymentsHistoryResponse,
    PnlRealizationEvent,
    PositionEvent,
    PositionSide,
    RequestFailure,
    RequestSequenced,
    RequestTransacted,
    RequestType,
    ResponseApi,
    ResponseStatus,
    SafetyValidation,
    SequencingValidation,
    StrategyEvent,
    TradesHistoryResponse,
    UIPosition,
    UIStrategy,
    UIToastActionID,
    UIToastStatus,
    UIToastTitle,
    WithdrawIntent,
} from '@derivadex/types';
import {
    as_nonce,
    createCancelIntentTypedData,
    createWithdrawIntentTypedData,
    DerivadexSocket,
    getErrorMessage,
    getFrontendLogger,
    liquidationPriceForPositionCalc,
    ONE_CLICK_TRADING_DATA,
    RequestIntentTimeoutError,
    signTypedDataForEthersWithPrivateKey,
    transformTypedDataForEthers,
} from '@derivadex/utils';
import i18n from 'i18next';
import { eventChannel } from 'redux-saga';
import { getOperatorConfig } from 'store/config/selectors';
import { getLeaderboardInitalLoadFlag } from 'store/leaderboard/selectors';
import { RESET_LEADERBOARD_PAGE } from 'store/leaderboard/slice';
import { getMarketsAsList, getPrices, getSelectedMarket, getSelectedMarketPrice } from 'store/market/selectors';
import { getPositionsHistoryDirtyFlag } from 'store/portfolio/selectors';
import { RESET_PORTFOLIO_PAGE } from 'store/portfolio/slice';
import { getLiquidationPriceAlertStatus } from 'store/profile/selectors';
import { waitFor } from 'store/saga';
import { getSocket } from 'store/socket/selectors';
import {
    ADD_ALERT_NOTIFICATION,
    ADD_LIQUIDATION_ALERT_NOTIFICATION,
    ADD_TOAST_MESSAGE,
    ALERT_STATE_RESET,
    CancelUIState,
    ClosePositionUIState,
    ModifyOrderUIState,
    PlaceOrderUIState,
    SET_CANCEL_UI_STATE,
    SET_CLOSE_POSITION_UI_STATE,
    SET_MODIFY_ORDER_UI_STATE,
    SET_PLACE_ORDER_UI_STATE,
    SET_WITHDRAW_INTENT_USDC_UI_STATE,
    WithdrawIntentUsdcUIState,
} from 'store/ui/slice';
import { getEthAddress, getTwentyOneByteEthAddress, getWeb3Context } from 'store/web3/selectors';
import { CONNECT_WEB3, WITHDRAW_STATE_UPDATE_TRIGGER } from 'store/web3/slice';
import { all, call, fork, put, putResolve, select, take, takeEvery, takeLatest } from 'typed-redux-saga/macro';
import { DEFAULT_STRATEGY, OPERATOR_DECIMAL_MULTIPLIER, ORDER_ZERO_PADDING } from 'utils/constants';
import { openAskSize, openBidSize, StrategyFormulas } from 'utils/strategy_formulas';
import { getRequestFailureLink, getRequestFailureToastStatus } from 'utils/toasts';
import { getUIPosition } from 'utils/ui_positions';
import { signTypedMessage } from 'utils/web3_api';

import { getOpenOrders, getPositions, getPositionsAsList, getSelectedStrategy, getStrategies } from './selectors';
import {
    ACTIVATE_PAYMENTS_HISTORY_DIRTY_FLAG,
    ACTIVATE_POSITIONS_CALCULATIONS_DIRTY_FLAG,
    ACTIVATE_STRATEGY_CALCULATIONS_DIRTY_FLAG,
    ACTIVATE_TRADES_HISTORY_DIRTY_FLAG,
    CANCEL_ORDER_INTENT,
    CLOSE_POSITION,
    MODIFY_ORDER_INTENT,
    PAYMENTS_HISTORY_REQUEST,
    PLACE_ORDER_INTENT,
    RESET_ACTIVE_STRATEGY,
    SET_ACTIVE_STRATEGY,
    SET_OPEN_ORDERS,
    SET_PAYMENTS_HISTORY,
    SET_POSITIONS,
    SET_TRADES_HISTORY,
    TRADES_HISTORY_REQUEST,
    UPDATE_POSITIONS,
    UPDATE_STRATEGIES,
    WITHDRAW_STRATEGY_INTENT,
} from './slice';
import {
    encryptIntent,
    encryptIntentHelper,
    getRejectionReasonMessageId,
    mergeOpenOrdersAfterUpdate,
    signModifyOrderIntent,
    signOrderIntent,
    submitModifyOrderIntent,
    submitOrderIntent,
} from './utils';

function* handleOrdersFeedUpdate(payload: OrderIntentEvent | OrderRejectEvent) {
    try {
        if (payload.e === FeedEventType.PARTIAL) {
            const orderIntents = payload.c;
            yield* putResolve(SET_OPEN_ORDERS(orderIntents));
        } else if (payload.e === FeedEventType.ERROR) {
            const rejected = payload.c[0] as OrderReject;
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    title: UIToastTitle.PlaceOrder,
                    status: UIToastStatus.Error,
                    description: i18n.t(
                        getRejectionReasonMessageId(
                            rejected.originalAmount.minus(rejected.amount).gt(0),
                            rejected.amount,
                            rejected.reason,
                        ),
                    ),
                    actionID: UIToastActionID.SubmitOrder,
                    link: getRequestFailureLink(SequencingValidation.OrderNotExecuted, ''),
                }),
            );
        } else if (payload.e === FeedEventType.UPDATE) {
            const twentyOneByteEthAddress = yield* call(waitFor<string>, getTwentyOneByteEthAddress);
            const orders = yield* select(getOpenOrders);
            const { isMerged, result } = yield* call(
                mergeOpenOrdersAfterUpdate,
                orders,
                payload.c,
                twentyOneByteEthAddress,
            );
            if (isMerged) {
                yield* putResolve(SET_OPEN_ORDERS(result));
            }
        }
        yield* putResolve(ACTIVATE_STRATEGY_CALCULATIONS_DIRTY_FLAG());
    } catch (error: any) {
        getFrontendLogger().logError(getErrorMessage(error));
    }
}

function* handleStrategyFeedUpdate(payload: StrategyEvent) {
    let strategies: UIStrategy[] = [];
    const twentyOneByteEthAddress = yield* call(waitFor<string>, getTwentyOneByteEthAddress);
    if (payload.c.length === 0) {
        const newStrategy = {
            trader: twentyOneByteEthAddress,
            strategy: DEFAULT_STRATEGY,
            strategyHash: '0x00',
            availCollateral: new BigNumber(0),
            lockedCollateral: new BigNumber(0),
            frozen: false,
            maxLeverage: 3,
            isNew: true,
            mf: new BigNumber(0),
            mmr: new BigNumber(0),
            leverage: new BigNumber(0),
            frontendMargin: new BigNumber(0),
            strategyValue: new BigNumber(0),
        };
        strategies = [newStrategy];
        yield* putResolve(UPDATE_STRATEGIES(strategies));
    } else {
        for (const strategy of payload.c) {
            const calculations = yield* call(runStrategyCalculations, strategy.availCollateral, strategy.maxLeverage);
            strategies.push({
                ...strategy,
                isNew: false,
                ...calculations,
            });
        }
        yield* putResolve(UPDATE_STRATEGIES(strategies));
        yield* putResolve(ACTIVATE_PAYMENTS_HISTORY_DIRTY_FLAG());
        yield* putResolve(ACTIVATE_TRADES_HISTORY_DIRTY_FLAG());
    }
    const selected = yield* select(getSelectedStrategy);
    if (selected === undefined && payload.e === FeedEventType.PARTIAL) {
        yield* putResolve(
            SET_ACTIVE_STRATEGY({ strategyId: DEFAULT_STRATEGY, trader: twentyOneByteEthAddress, isInitialLoad: true }),
        );
    }
    //yield* putResolve(WITHDRAW_STATE_UPDATE_TRIGGER());
}

function* handlePositionFeedUpdate(payload: PositionEvent) {
    const positions: UIPosition[] = [];
    const strategy = yield* call(waitFor<UIStrategy>, getSelectedStrategy);
    if (payload.e === FeedEventType.PARTIAL) {
        const uiPositions: UIPosition[] = payload.c.map((it) => getUIPosition(it));
        for (const position of uiPositions) {
            const calculations = yield* call(runPositionCalculations, position, strategy);
            positions.push({ ...position, ...calculations });
        }
        yield* putResolve(SET_POSITIONS(positions));
    } else if (payload.e === FeedEventType.UPDATE) {
        const uiPositions: UIPosition[] = payload.c.map((it) => getUIPosition(it));
        for (const position of uiPositions) {
            const calculations = yield* call(runPositionCalculations, position, strategy);
            positions.push({ ...position, ...calculations });
        }
        yield* putResolve(UPDATE_POSITIONS(positions));
    }
    yield* putResolve(ACTIVATE_STRATEGY_CALCULATIONS_DIRTY_FLAG());
}

function* handleLiquidationFeedUpdate(payload: LiquidationEvent) {
    if (payload.e === FeedEventType.PARTIAL) {
        yield* putResolve(ADD_ALERT_NOTIFICATION({ notification: payload, trader: payload.c.trader }));
    } else if (payload.e === FeedEventType.UPDATE) {
        yield* putResolve(ADD_ALERT_NOTIFICATION({ notification: payload, trader: payload.c.trader }));
    }
}

function* handlePnlRealizationFeedUpdate(payload: PnlRealizationEvent) {
    if (payload.e === FeedEventType.PARTIAL) {
        yield* putResolve(ADD_ALERT_NOTIFICATION({ notification: payload, trader: payload.c.trader }));
    } else if (payload.e === FeedEventType.UPDATE) {
        const positions = yield* select(getPositionsAsList);
        // The pnl realization is only relevant for the user
        // if he has positions currently.
        if (positions.length > 0) {
            yield* putResolve(ADD_ALERT_NOTIFICATION({ notification: payload, trader: payload.c.trader }));
        }
    }
}

export function* handleStrategy(socket: DerivadexSocket) {
    try {
        const channel = yield* call(getStrategyFeedEventChannel, socket);
        while (true) {
            const message = yield* take(channel);
            switch (message.t) {
                case AccountEventType.ORDER:
                    yield* call(handleOrdersFeedUpdate, message);
                    break;
                case AccountEventType.STRATEGY:
                    yield* call(handleStrategyFeedUpdate, message);
                    break;
                case AccountEventType.POSITION:
                    yield* call(handlePositionFeedUpdate, message);
                    break;
                case AccountEventType.LIQUIDATION:
                    yield* call(handleLiquidationFeedUpdate, message);
                    break;
                case AccountEventType.PNL_REALIZATION:
                    yield* call(handlePnlRealizationFeedUpdate, message);
                    break;
                default:
                    break;
            }
        }
    } catch (error) {
        getFrontendLogger().logError(error);
    }
}

export function getStrategyFeedEventChannel(socket: DerivadexSocket) {
    return eventChannel<AccountMessage>((emitter) => {
        socket.onAccountUpdate((value) => emitter(value));
        return () => {
            socket.close();
        };
    });
}

function* runStrategyCalculations(strategyCollateral: BigNumber, strategyMaxLeverage: number) {
    try {
        yield* call(waitFor<Market>, getSelectedMarket);
        const markets = yield* select(getMarketsAsList);
        const positions = yield* select(getPositions);
        const openOrders = yield* select(getOpenOrders);
        const markPrices = yield* select(getPrices);
        const positionsIncludingNotOpened = [];
        const strategyFormulas = new StrategyFormulas();
        if (markets && markets.length > 0) {
            yield* call([strategyFormulas, strategyFormulas.updateContext], strategyCollateral, strategyMaxLeverage);
            for (const m of markets) {
                const position = positions[m.symbol];
                const markPrice = markPrices[m.symbol];
                const price = markPrice?.price || new BigNumber(0);
                if (position !== undefined) {
                    positionsIncludingNotOpened.push(position);
                    yield* call(
                        [strategyFormulas, strategyFormulas.updateLiquidityContext],
                        m.symbol,
                        price,
                        openBidSize(openOrders, position.symbol),
                        openAskSize(openOrders, position.symbol),
                        position.side,
                        position.balance,
                        position.avgEntryPx,
                    );
                } else {
                    positionsIncludingNotOpened.push({
                        symbol: m.symbol,
                        balance: new BigNumber(0),
                        side: PositionSide.None,
                        avgEntryPx: new BigNumber(0),
                    } as UIPosition);
                    yield* call(
                        [strategyFormulas, strategyFormulas.updateLiquidityContext],
                        m.symbol,
                        price,
                        openBidSize(openOrders, m.symbol),
                        openAskSize(openOrders, m.symbol),
                        PositionSide.Long,
                        new BigNumber(0),
                        new BigNumber(0),
                    );
                }
            }
            // TODO: 3691 including unsettled margin in frontend UI
            const frontendMargin: BigNumber = yield* call([strategyFormulas, strategyFormulas.strategyMarginCalc]);
            const strategyValue: BigNumber = yield* call([strategyFormulas, strategyFormulas.strategyValueCalc]);
            const leverage: BigNumber = yield* call([strategyFormulas, strategyFormulas.strategyLeverageCalc]);
            const mmr: BigNumber = yield* call([strategyFormulas, strategyFormulas.maintenanceMarginRequirementsCalc]);
            const mf: BigNumber = yield* call([strategyFormulas, strategyFormulas.marginFractionCalc]);

            return { mf, mmr, leverage, frontendMargin, strategyValue };
        }
    } catch (error: any) {
        getFrontendLogger().logError(getErrorMessage(error));
    }
    return {
        mf: new BigNumber(0),
        mmr: new BigNumber(0),
        leverage: new BigNumber(0),
        frontendMargin: new BigNumber(0),
        strategyValue: new BigNumber(0),
    };
}

function* runPositionCalculations(position: UIPosition, strategy: UIStrategy) {
    try {
        const markPrice = yield* select(getSelectedMarketPrice, position.symbol);
        const isLiquidationPriceAlertEnabled = yield* select(getLiquidationPriceAlertStatus);
        if (strategy && markPrice) {
            const strategyFormulas = new StrategyFormulas();
            const unrealizedPnl = yield* call(
                [strategyFormulas, strategyFormulas.unrealizedPnlForPositionCalc],
                position,
                markPrice.price,
            );
            const liquidationPrice = yield* call(
                liquidationPriceForPositionCalc,
                strategy.availCollateral,
                strategy.mmr,
                position,
            );
            if (isLiquidationPriceAlertEnabled) {
                if (position.side === PositionSide.Long) {
                    if (markPrice.price.multipliedBy(0.9).isLessThanOrEqualTo(liquidationPrice)) {
                        yield* putResolve(
                            ADD_LIQUIDATION_ALERT_NOTIFICATION({
                                notification: {
                                    t: 'LiquidationAlert',
                                    r: undefined,
                                    e: FeedEventType.UPDATE,
                                    c: {
                                        epoch: -1,
                                        txOrdinal: -1,
                                        createdAt: new Date(),
                                        value: markPrice.price,
                                        symbol: position.symbol,
                                    },
                                },
                                trader: strategy.trader,
                            }),
                        );
                        getFrontendLogger().log(
                            'long position liquidation alert',
                            position.symbol,
                            markPrice.price.toString(),
                            liquidationPrice.toString(),
                            unrealizedPnl.toString(),
                        );
                    }
                } else {
                    if (markPrice.price.multipliedBy(1.1).isGreaterThanOrEqualTo(liquidationPrice)) {
                        yield* putResolve(
                            ADD_LIQUIDATION_ALERT_NOTIFICATION({
                                notification: {
                                    t: 'LiquidationAlert',
                                    r: undefined,
                                    e: FeedEventType.UPDATE,
                                    c: {
                                        epoch: -1,
                                        txOrdinal: -1,
                                        createdAt: new Date(),
                                        value: markPrice.price,
                                        symbol: position.symbol,
                                    },
                                },
                                trader: strategy.trader,
                            }),
                        );
                        getFrontendLogger().log(
                            'ask position liquidation alert ',
                            position.symbol,
                            markPrice.price.toString(),
                            liquidationPrice.toString(),
                            unrealizedPnl.toString(),
                        );
                    }
                }
            }
            getFrontendLogger().log(
                'position calculation ',
                position.symbol,
                markPrice.price.toString(),
                liquidationPrice.toString(),
                unrealizedPnl.toString(),
            );
            return { liquidationPrice, unrealizedPnl };
        }
    } catch (error: any) {
        getFrontendLogger().logError('Run Position Calculations', getErrorMessage(error));
    }
    return {
        liquidationPrice: new BigNumber(0),
        unrealizedPnl: new BigNumber(0),
    };
}

function* onPlaceOrderRequest(action: ReturnType<typeof PLACE_ORDER_INTENT.request>): Generator {
    const orderIntent = { ...action.payload } as OrderIntent;
    getFrontendLogger().log('onPlaceOrderRequest orderIntent payload', orderIntent);
    try {
        yield* putResolve(
            ADD_TOAST_MESSAGE({
                status: UIToastStatus.Pending,
                title: i18n.t('confirmTransaction'),
                description: i18n.t('waitingForWalletConfirmation'),
                actionID: UIToastActionID.SubmitOrder,
            }),
        );
        yield* putResolve(SET_PLACE_ORDER_UI_STATE(PlaceOrderUIState.PENDING_WALLET_CONFIRMATION));
        const socket = yield* call(waitFor<DerivadexSocket>, getSocket);
        const context = yield* call(waitFor<Context>, getWeb3Context);
        const traderAddress = yield* call(waitFor<string>, getEthAddress);
        yield* putResolve(SET_PLACE_ORDER_UI_STATE(PlaceOrderUIState.PENDING_TRANSACTION_CONFIRMATIONS));
        const serializedOneClickTradingDataMap = window.localStorage.getItem(ONE_CLICK_TRADING_DATA);
        let oneClickTradingData = undefined;
        if (serializedOneClickTradingDataMap !== null) {
            const deserializedMap = new Map<string, OneClickTradingStorageData>(
                Object.entries(JSON.parse(serializedOneClickTradingDataMap)),
            );
            oneClickTradingData = deserializedMap.get(traderAddress) as OneClickTradingStorageData;
        }
        getFrontendLogger().log('order intent placed', orderIntent);
        orderIntent.signature = yield* call(signOrderIntent, orderIntent, context, oneClickTradingData);
        getFrontendLogger().log('order intent signature', orderIntent.signature);
        const operatorConfig = yield* call(waitFor<OperatorConfig>, getOperatorConfig);
        const response = yield* call(
            submitOrderIntent,
            orderIntent,
            traderAddress,
            socket,
            operatorConfig.encryptionKey,
            oneClickTradingData,
        );
        getFrontendLogger().log('place order intent response', response);
        if (response.e === ResponseStatus.SUCCESS) {
            getFrontendLogger().log('place order response success', response.c, response.e, response.t);
            yield* putResolve(PLACE_ORDER_INTENT.success(response.c as RequestTransacted));
            yield* putResolve(SET_PLACE_ORDER_UI_STATE(PlaceOrderUIState.TRANSACTION_CONFIRMED));
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    title: UIToastTitle.PlaceOrder,
                    status: UIToastStatus.Successful,
                    description:
                        action.payload.orderType === OrderType.Market
                            ? i18n.t('placeMarketOrderSuccess')
                            : i18n.t('placeOrderSuccess'),
                    actionID: UIToastActionID.SubmitOrder,
                }),
            );
        } else {
            const payload: RequestFailure = response.c as RequestFailure;
            const sequencingValidation = payload.c.inner;
            const toastDescription = payload.c && payload.c.inner;
            yield* putResolve(PLACE_ORDER_INTENT.failure(response.c as RequestFailure));
            yield* putResolve(SET_PLACE_ORDER_UI_STATE(PlaceOrderUIState.TRANSACTION_VALIDATION_FAILED));
            if (sequencingValidation !== SafetyValidation.TxLogTypeMismatch) {
                yield* putResolve(
                    ADD_TOAST_MESSAGE({
                        title: UIToastTitle.PlaceOrder,
                        status: getRequestFailureToastStatus(sequencingValidation, toastDescription),
                        description: toastDescription,
                        actionID: UIToastActionID.SubmitOrder,
                        link: getRequestFailureLink(sequencingValidation, toastDescription),
                    }),
                );
                getFrontendLogger().logError(
                    `System malfunction: Request Failure for Submit Order Intent (${sequencingValidation} | ${payload.c.message})`,
                );
            }
        }
    } catch (error: any) {
        if (error instanceof RequestIntentTimeoutError) {
            const payload: RequestFailure = {
                t: 'ClientTimeoutError',
                c: { nonce: '0x00', inner: SequencingValidation.OrderNotExecuted, message: '' },
            };
            const sequencingValidation = payload.c.inner;
            const toastDescription = payload.c && payload.c.inner;
            yield* putResolve(SET_PLACE_ORDER_UI_STATE(PlaceOrderUIState.TRANSACTION_EXECUTION_FAILED));
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    title: UIToastTitle.PlaceOrder,
                    status: getRequestFailureToastStatus(sequencingValidation, toastDescription),
                    description: toastDescription,
                    actionID: UIToastActionID.SubmitOrder,
                    link: getRequestFailureLink(sequencingValidation, toastDescription),
                }),
            );
        } else {
            getFrontendLogger().logError(getErrorMessage(error));
            yield* put(PLACE_ORDER_INTENT.failure(error));
            yield* putResolve(SET_PLACE_ORDER_UI_STATE(PlaceOrderUIState.WALLET_REJECTED));
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    status: UIToastStatus.Failed,
                    title: i18n.t('orderCancelled'),
                    description: i18n.t('userCancelledOrder'),
                    actionID: UIToastActionID.SubmitOrder,
                }),
            );
        }
    }
}

export function* watchPlaceOrderRequest() {
    yield* takeLatest(PLACE_ORDER_INTENT.request, onPlaceOrderRequest);
}

function* onModifyOrderRequest(action: ReturnType<typeof MODIFY_ORDER_INTENT.request>): Generator {
    const orderIntent = { ...action.payload } as ModifyOrderIntent;
    try {
        yield* putResolve(
            ADD_TOAST_MESSAGE({
                status: UIToastStatus.Pending,
                title: i18n.t('confirmTransaction'),
                description: i18n.t('waitingForWalletConfirmation'),
                actionID: UIToastActionID.SubmitOrder,
            }),
        );
        yield* putResolve(SET_MODIFY_ORDER_UI_STATE(ModifyOrderUIState.PENDING_WALLET_CONFIRMATION));
        const socket = yield* call(waitFor<DerivadexSocket>, getSocket);
        const context = yield* call(waitFor<Context>, getWeb3Context);
        const traderAddress = yield* call(waitFor<string>, getEthAddress);
        yield* putResolve(SET_MODIFY_ORDER_UI_STATE(ModifyOrderUIState.PENDING_TRANSACTION_CONFIRMATIONS));
        const serializedOneClickTradingDataMap = window.localStorage.getItem(ONE_CLICK_TRADING_DATA);
        let oneClickTradingData = undefined;
        if (serializedOneClickTradingDataMap !== null) {
            const deserializedMap = new Map<string, OneClickTradingStorageData>(
                Object.entries(JSON.parse(serializedOneClickTradingDataMap)),
            );
            oneClickTradingData = deserializedMap.get(traderAddress) as OneClickTradingStorageData;
        }
        getFrontendLogger().log('modify order intent placed', orderIntent);
        orderIntent.signature = yield* call(signModifyOrderIntent, orderIntent, context, oneClickTradingData);
        getFrontendLogger().log('modify order intent signature', orderIntent.signature);
        const operatorConfig = yield* call(waitFor<OperatorConfig>, getOperatorConfig);
        const response = yield* call(
            submitModifyOrderIntent,
            orderIntent,
            traderAddress,
            socket,
            operatorConfig.encryptionKey,
            oneClickTradingData,
        );
        getFrontendLogger().log('submitModifyOrderIntent response', response);
        if (response.e === ResponseStatus.SUCCESS) {
            yield* putResolve(MODIFY_ORDER_INTENT.success(response.c as RequestTransacted));
            yield* putResolve(SET_MODIFY_ORDER_UI_STATE(ModifyOrderUIState.TRANSACTION_CONFIRMED));
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    title: UIToastTitle.PlaceOrder,
                    status: UIToastStatus.Successful,
                    description: i18n.t('modifyOrderSuccess'),
                    actionID: UIToastActionID.SubmitOrder,
                }),
            );
        } else {
            const payload: RequestFailure = response.c as RequestFailure;
            const sequencingValidation = payload.c.inner;
            const toastDescription = payload.c && payload.c.inner;
            yield* putResolve(MODIFY_ORDER_INTENT.failure(response.c as RequestFailure));
            yield* putResolve(SET_MODIFY_ORDER_UI_STATE(ModifyOrderUIState.TRANSACTION_VALIDATION_FAILED));
            if (sequencingValidation !== SafetyValidation.TxLogTypeMismatch) {
                yield* putResolve(
                    ADD_TOAST_MESSAGE({
                        title: UIToastTitle.PlaceOrder,
                        status: getRequestFailureToastStatus(sequencingValidation, toastDescription),
                        description: toastDescription,
                        actionID: UIToastActionID.SubmitOrder,
                        link: getRequestFailureLink(sequencingValidation, toastDescription),
                    }),
                );
                getFrontendLogger().logError(
                    `System malfunction: Request Failure for Submit ModifyOrder Intent (${sequencingValidation} | ${payload.c.message})`,
                );
            }
        }
    } catch (error: any) {
        if (error instanceof RequestIntentTimeoutError) {
            const payload: RequestFailure = {
                t: 'ClientTimeoutError',
                c: { nonce: '0x00', inner: SequencingValidation.OrderNotExecuted, message: '' },
            };
            const sequencingValidation = payload.c.inner;
            const toastDescription = payload.c && payload.c.inner;
            yield* putResolve(SET_MODIFY_ORDER_UI_STATE(ModifyOrderUIState.TRANSACTION_EXECUTION_FAILED));
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    title: UIToastTitle.PlaceOrder,
                    status: getRequestFailureToastStatus(sequencingValidation, toastDescription),
                    description: toastDescription,
                    actionID: UIToastActionID.SubmitOrder,
                    link: getRequestFailureLink(sequencingValidation, toastDescription),
                }),
            );
        } else {
            getFrontendLogger().logError(getErrorMessage(error));
            yield* put(MODIFY_ORDER_INTENT.failure(error));
            yield* putResolve(SET_MODIFY_ORDER_UI_STATE(ModifyOrderUIState.WALLET_REJECTED));
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    status: UIToastStatus.Failed,
                    title: i18n.t('orderCancelled'),
                    description: i18n.t('userCancelledOrder'),
                    actionID: UIToastActionID.SubmitOrder,
                }),
            );
        }
    }
}

export function* watchModifyOrderRequest() {
    yield* takeLatest(MODIFY_ORDER_INTENT.request, onModifyOrderRequest);
}

function* onClosePositionRequest(action: ReturnType<typeof CLOSE_POSITION.request>): Generator {
    try {
        yield* putResolve(
            ADD_TOAST_MESSAGE({
                status: UIToastStatus.Pending,
                title: i18n.t('confirmTransaction'),
                description: i18n.t('waitingForWalletConfirmation'),
                actionID: UIToastActionID.SubmitOrder,
            }),
        );
        yield* putResolve(SET_CLOSE_POSITION_UI_STATE(ClosePositionUIState.PENDING_WALLET_CONFIRMATION));
        const socket = yield* call(waitFor<DerivadexSocket>, getSocket);
        const context = yield* call(waitFor<Context>, getWeb3Context);
        const strategyData = yield* call(waitFor<UIStrategy>, getSelectedStrategy);
        const twentyOneByteEthAddress = yield* call(waitFor<string>, getTwentyOneByteEthAddress);
        const traderAddress = yield* call(waitFor<string>, getEthAddress);
        const nonce = yield* call(as_nonce, Date.now());
        const orderIntent = {
            traderAddress: twentyOneByteEthAddress,
            symbol: action.payload.symbol,
            strategy: strategyData.strategy,
            side: action.payload.side === PositionSide.Long ? OrderSide.Ask : OrderSide.Bid,
            orderType: OrderType.Market,
            nonce,
            amount: action.payload.balance,
            price: new BigNumber(0),
            stopPrice: new BigNumber(0),
            signature: '0x0',
        } as OrderIntent;
        const serializedOneClickTradingDataMap = window.localStorage.getItem(ONE_CLICK_TRADING_DATA);
        let oneClickTradingData = undefined;
        if (serializedOneClickTradingDataMap !== null) {
            const deserializedMap = new Map<string, OneClickTradingStorageData>(
                Object.entries(JSON.parse(serializedOneClickTradingDataMap)),
            );
            oneClickTradingData = deserializedMap.get(traderAddress) as OneClickTradingStorageData;
        }
        orderIntent.signature = yield* call(signOrderIntent, orderIntent, context, oneClickTradingData);
        yield* putResolve(SET_CLOSE_POSITION_UI_STATE(ClosePositionUIState.PENDING_TRANSACTION_CONFIRMATIONS));
        const operatorConfig = yield* call(waitFor<OperatorConfig>, getOperatorConfig);
        const response = yield* call(
            submitOrderIntent,
            orderIntent,
            traderAddress,
            socket,
            operatorConfig.encryptionKey,
            oneClickTradingData,
        );
        if (response.e === ResponseStatus.SUCCESS) {
            yield* putResolve(PLACE_ORDER_INTENT.success(response.c as RequestTransacted));
            yield* putResolve(SET_CLOSE_POSITION_UI_STATE(ClosePositionUIState.NONE));
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    title: UIToastTitle.PlaceOrder,
                    status: UIToastStatus.Successful,
                    description: i18n.t('placeMarketOrderSuccess'),
                    actionID: UIToastActionID.SubmitOrder,
                }),
            );
        } else {
            const payload: RequestFailure = response.c as RequestFailure;
            const sequencingValidation = payload.c.inner;
            const toastDescription = payload.c && payload.c.inner;
            yield* putResolve(PLACE_ORDER_INTENT.failure(response.c as RequestFailure));
            yield* putResolve(SET_CLOSE_POSITION_UI_STATE(ClosePositionUIState.TRANSACTION_VALIDATION_FAILED));
            if (sequencingValidation !== SafetyValidation.TxLogTypeMismatch) {
                yield* putResolve(
                    ADD_TOAST_MESSAGE({
                        title: UIToastTitle.PlaceOrder,
                        status: getRequestFailureToastStatus(sequencingValidation, toastDescription),
                        description: toastDescription,
                        actionID: UIToastActionID.SubmitOrder,
                        link: getRequestFailureLink(sequencingValidation, toastDescription),
                    }),
                );
                getFrontendLogger().logError(
                    `System malfunction: Request Failure for Submit Order Intent (${sequencingValidation} | ${payload.c.message})`,
                );
            }
        }
    } catch (error: any) {
        if (error instanceof RequestIntentTimeoutError) {
            const payload: RequestFailure = {
                t: 'ClientTimeoutError',
                c: { nonce: '0x00', inner: SequencingValidation.OrderNotExecuted, message: '' },
            };
            const sequencingValidation = payload.c.inner;
            const toastDescription = payload.c && payload.c.inner;
            yield* putResolve(SET_CLOSE_POSITION_UI_STATE(ClosePositionUIState.TRANSACTION_EXECUTION_FAILED));
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    title: UIToastTitle.PlaceOrder,
                    status: getRequestFailureToastStatus(sequencingValidation, toastDescription),
                    description: toastDescription,
                    actionID: UIToastActionID.SubmitOrder,
                    link: getRequestFailureLink(sequencingValidation, toastDescription),
                }),
            );
        } else {
            getFrontendLogger().logError(getErrorMessage(error));
            yield* put(PLACE_ORDER_INTENT.failure(error));
            yield* putResolve(SET_CLOSE_POSITION_UI_STATE(ClosePositionUIState.WALLET_REJECTED));
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    status: UIToastStatus.Failed,
                    title: i18n.t('orderCancelled'),
                    description: i18n.t('userCancelledOrder'),
                    actionID: UIToastActionID.SubmitOrder,
                }),
            );
        }
    }
}

export function* watchClosePosition() {
    yield* takeLatest(CLOSE_POSITION.request, onClosePositionRequest);
}

function* onCancelOrderRequest(action: ReturnType<typeof CANCEL_ORDER_INTENT.request>): Generator {
    try {
        yield* putResolve(
            ADD_TOAST_MESSAGE({
                status: UIToastStatus.Pending,
                title: i18n.t('confirmTransaction'),
                description: i18n.t('waitingForWalletConfirmation'),
                actionID: UIToastActionID.SubmitOrder,
            }),
        );
        const socket = yield* call(waitFor<DerivadexSocket>, getSocket);
        const context = yield* call(waitFor<Context>, getWeb3Context);
        const traderAddress = yield* call(waitFor<string>, getEthAddress);
        const cancelIntentWithPadding = { ...action.payload, orderHash: action.payload.orderHash + ORDER_ZERO_PADDING };
        const cancelOrderIntentTyped = yield* call(
            createCancelIntentTypedData,
            cancelIntentWithPadding,
            context.chainId,
            context.deployment,
            context.contractAddresses.derivaDEXAddress,
        );
        const typedDataForEthers = yield* call(transformTypedDataForEthers, cancelOrderIntentTyped);
        const serializedOneClickTradingDataMap = window.localStorage.getItem(ONE_CLICK_TRADING_DATA);
        let oneClickTradingData = undefined;
        if (serializedOneClickTradingDataMap !== null) {
            const deserializedMap = new Map<string, OneClickTradingStorageData>(
                Object.entries(JSON.parse(serializedOneClickTradingDataMap)),
            );
            oneClickTradingData = deserializedMap.get(traderAddress) as OneClickTradingStorageData;
        }
        let signature = '';
        if (oneClickTradingData?.enabled) {
            signature = oneClickTradingData.signature;
            signature = yield* call(
                signTypedDataForEthersWithPrivateKey,
                typedDataForEthers,
                oneClickTradingData.sessionPrivateKey,
            );
        } else {
            signature = yield* call(signTypedMessage, typedDataForEthers);
        }
        action.payload.signature = signature;
        const intent = { t: 'CancelOrder', c: action.payload };
        const operatorConfig = yield* call(waitFor<OperatorConfig>, getOperatorConfig);
        const encryptedIntent = yield* call(
            encryptIntentHelper,
            intent,
            operatorConfig.encryptionKey,
            oneClickTradingData,
        );
        const response: ResponseApi<RequestSequenced | RequestTransacted | RequestFailure> = yield* call(
            [socket, socket.submitIntent],
            {
                t: RequestType.CANCEL_INTENT,
                c: { traderAddress, encryptedIntent },
            },
        );
        if (response.e === ResponseStatus.SUCCESS) {
            yield* putResolve(CANCEL_ORDER_INTENT.success(response.c as RequestTransacted));
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    title: UIToastTitle.CancelOrder,
                    status: UIToastStatus.Successful,
                    description: i18n.t('cancelOrderSuccess'),
                    actionID: UIToastActionID.SubmitOrder,
                }),
            );
        } else {
            const payload: RequestFailure = response.c as RequestFailure;
            const sequencingValidation = payload.c.inner;
            const toastDescription = payload.t && payload.c && payload.c.inner;
            yield* putResolve(CANCEL_ORDER_INTENT.failure(payload));
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    title: UIToastTitle.CancelOrder,
                    status: getRequestFailureToastStatus(sequencingValidation, toastDescription),
                    description: toastDescription,
                    actionID: UIToastActionID.SubmitOrder,
                    link: getRequestFailureLink(sequencingValidation, toastDescription),
                }),
            );
            getFrontendLogger().logError(
                `System malfunction: Request Failure for Submit CancelOrder Intent (${sequencingValidation} | ${payload.c.message})`,
            );
        }
    } catch (error: any) {
        getFrontendLogger().logError(getErrorMessage(error));
        yield* put(CANCEL_ORDER_INTENT.failure(error));
        yield* put(
            ADD_TOAST_MESSAGE({
                title: UIToastTitle.CancelOrder,
                status: UIToastStatus.Failed,
                description: i18n.t('userRejectedRequest'),
                actionID: UIToastActionID.SubmitOrder,
            }),
        );
    } finally {
        yield* putResolve(SET_CANCEL_UI_STATE(CancelUIState.NONE));
    }
}

export function* watchCancelOrderRequest() {
    yield* takeLatest(CANCEL_ORDER_INTENT.request, onCancelOrderRequest);
}

function* onWithdrawStrategyRequest(action: ReturnType<typeof WITHDRAW_STRATEGY_INTENT.request>): Generator {
    yield* putResolve(SET_WITHDRAW_INTENT_USDC_UI_STATE(WithdrawIntentUsdcUIState.PENDING_WALLET_CONFIRMATION));
    yield* putResolve(
        ADD_TOAST_MESSAGE({
            status: UIToastStatus.Pending,
            title: i18n.t('confirmTransaction'),
            description: i18n.t('waitingForWalletConfirmation'),
            actionID: UIToastActionID.WithdrawCollateral,
        }),
    );

    try {
        const socket = yield* call(waitFor<DerivadexSocket>, getSocket);
        const context = yield* call(waitFor<Context>, getWeb3Context);
        const traderAddress = yield* call(waitFor<string>, getEthAddress);
        const scaledWithdrawIntent: WithdrawIntent = {
            ...action.payload,
            amount: action.payload.amount.multipliedBy(OPERATOR_DECIMAL_MULTIPLIER),
        };
        const scaledWithdrawIntentTyped = yield* call(
            createWithdrawIntentTypedData,
            scaledWithdrawIntent,
            context.chainId,
            context.deployment,
            context.contractAddresses.derivaDEXAddress,
        );
        const typedDataForEthers = yield* call(transformTypedDataForEthers, scaledWithdrawIntentTyped);
        const signature = yield* call(signTypedMessage, typedDataForEthers);
        action.payload.signature = signature;
        const intent = { t: 'Withdraw', c: action.payload };
        const operatorConfig = yield* call(waitFor<OperatorConfig>, getOperatorConfig);
        const encryptedIntent: string = yield* call(encryptIntent, operatorConfig.encryptionKey, intent, null);
        yield* putResolve(SET_WITHDRAW_INTENT_USDC_UI_STATE(WithdrawIntentUsdcUIState.IN_PROGRESS));
        const response: ResponseApi<RequestSequenced | RequestTransacted | RequestFailure> = yield* call(
            [socket, socket.submitIntent],
            {
                t: RequestType.WITHDRAW_STRATEGY_INTENT,
                c: { traderAddress, encryptedIntent },
            },
        );
        if (response.e === ResponseStatus.SUCCESS) {
            yield* putResolve(WITHDRAW_STRATEGY_INTENT.success(response.c as RequestSequenced));
            yield* putResolve(WITHDRAW_STATE_UPDATE_TRIGGER());
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    title: UIToastTitle.Withdraw,
                    status: UIToastStatus.Successful,
                    description: i18n.t('withdrawInitiateSuccess'),
                    actionID: UIToastActionID.WithdrawCollateral,
                }),
            );
        } else {
            const failureResponse = response.c as RequestFailure;
            yield* putResolve(WITHDRAW_STRATEGY_INTENT.failure(failureResponse));
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    title: UIToastTitle.Withdraw,
                    status: UIToastStatus.Failed,
                    description: failureResponse.c.inner,
                    actionID: UIToastActionID.WithdrawCollateral,
                }),
            );
            getFrontendLogger().logError(
                `System malfunction: Request Failure for Submit Withdraw Intent (${failureResponse.c.inner} | ${failureResponse.c.message})`,
            );
        }
    } catch (error: any) {
        getFrontendLogger().logError(getErrorMessage(error));
        yield* put(WITHDRAW_STRATEGY_INTENT.failure(error));
        yield* putResolve(
            ADD_TOAST_MESSAGE({
                status: UIToastStatus.Failed,
                title: UIToastTitle.Withdraw,
                description: i18n.t('initateWithdrawalRejected'),
                actionID: UIToastActionID.WithdrawCollateral,
            }),
        );
    } finally {
        yield* putResolve(SET_WITHDRAW_INTENT_USDC_UI_STATE(WithdrawIntentUsdcUIState.NONE));
    }
}

export function* watchWithdrawStrategyRequest() {
    yield* takeLatest(WITHDRAW_STRATEGY_INTENT.request, onWithdrawStrategyRequest);
}

function* onPaymentsHistoryRequest(action: ReturnType<typeof PAYMENTS_HISTORY_REQUEST>): Generator {
    try {
        const socket = yield* call(waitFor<DerivadexSocket>, getSocket);
        const response: PaymentsHistoryResponse = yield* call([socket, socket.requestPaymentsHistory], {
            t: RequestType.PAYMENTS_HISTORY,
            c: action.payload,
        });
        if (response.e === ResponseStatus.SUCCESS) {
            yield* putResolve(SET_PAYMENTS_HISTORY(response.c));
        } else {
            getFrontendLogger().logError('System malfunction: Unexpected PaymentsHistory response.');
        }
    } catch (error: any) {
        getFrontendLogger().logError(getErrorMessage(error));
    }
}

function* watchPaymentsHistoryRequest(): Generator {
    yield* takeLatest(ActionType.PAYMENTS_HISTORY_REQUEST, onPaymentsHistoryRequest);
}

function* onUserTradesRequest(action: ReturnType<typeof TRADES_HISTORY_REQUEST>): Generator {
    try {
        const socket = yield* call(waitFor<DerivadexSocket>, getSocket);
        const response: TradesHistoryResponse = yield* call([socket, socket.requestTradesHistory], {
            t: RequestType.TRADES_HISTORY,
            c: action.payload,
        });
        if (response.e === ResponseStatus.SUCCESS) {
            yield* putResolve(SET_TRADES_HISTORY(response.c.map((t) => ({ ...t, epochId: new BigNumber(t.epochId) }))));
        } else {
            getFrontendLogger().logError('System malfunction: Unexpected user trades response.');
        }
    } catch (error: any) {
        getFrontendLogger().logError(getErrorMessage(error));
    }
}

function* watchUserTradesRequest(): Generator {
    yield* takeLatest(ActionType.TRADES_HISTORY_REQUEST, onUserTradesRequest);
}

function* subscribeOrdersAndPositions() {
    try {
        const strategy = yield* call(waitFor<UIStrategy>, getSelectedStrategy);
        const socket = yield* call(waitFor<DerivadexSocket>, getSocket);
        const twentyOneByteEthAddress = yield* call(waitFor<string>, getTwentyOneByteEthAddress);
        yield* call(
            [socket, socket.sendAccountPositionAndOrderSubscriptionMessages],
            twentyOneByteEthAddress,
            strategy.strategy,
        );
        const portfolioDirtyFlag = yield* select(getPositionsHistoryDirtyFlag);
        if (!portfolioDirtyFlag) {
            yield* putResolve(RESET_PORTFOLIO_PAGE());
        }
    } catch (error: any) {
        getFrontendLogger().logError(getErrorMessage(error));
    }
}

function* subscribeStrategy() {
    try {
        const twentyOneByteEthAddress = yield* call(waitFor<string>, getTwentyOneByteEthAddress);
        yield* putResolve(ALERT_STATE_RESET(twentyOneByteEthAddress));
        const socket = yield* call(waitFor<DerivadexSocket>, getSocket);
        yield* putResolve(RESET_ACTIVE_STRATEGY());
        yield* call([socket, socket.sendAccountTraderStrategySubscriptionMessages], twentyOneByteEthAddress);
        const portfolioDirtyFlag = yield* select(getLeaderboardInitalLoadFlag);
        if (!portfolioDirtyFlag) {
            yield* putResolve(RESET_LEADERBOARD_PAGE());
        }
    } catch (error: any) {
        getFrontendLogger().logError(getErrorMessage(error));
    }
}

export function* watchEthAddress() {
    yield* takeEvery(CONNECT_WEB3, subscribeStrategy);
}

function* onStrategyCalculationsDirtyFlag() {
    try {
        const strategies = yield* select(getStrategies);
        const strategiesAfterCalculations: UIStrategy[] = [];
        for (const strategy of Object.values(strategies)) {
            const calculations = yield* call(runStrategyCalculations, strategy.availCollateral, strategy.maxLeverage);
            strategiesAfterCalculations.push({
                ...strategy,
                ...calculations,
            });
        }
        yield* putResolve(UPDATE_STRATEGIES(strategiesAfterCalculations));
    } catch (error: any) {
        getFrontendLogger().logError(getErrorMessage(error));
    }
}

function* onPositionsCalculationsDirtyFlag() {
    try {
        const positions = yield* select(getPositionsAsList);
        const positionsAfterCalculations: UIPosition[] = [];
        const strategy = yield* call(waitFor<UIStrategy>, getSelectedStrategy);
        for (const position of positions) {
            const calculations = yield* call(runPositionCalculations, position, strategy);
            positionsAfterCalculations.push({ ...position, ...calculations });
        }
        yield* putResolve(SET_POSITIONS(positionsAfterCalculations));
        yield* putResolve(ACTIVATE_STRATEGY_CALCULATIONS_DIRTY_FLAG());
    } catch (error: any) {
        getFrontendLogger().logError(getErrorMessage(error));
    }
}

export function* watchActiveStrategy() {
    yield* takeLatest(SET_ACTIVE_STRATEGY, subscribeOrdersAndPositions);
}

export function* watchStrategyCalculationsDirtyFlag() {
    yield* takeLatest(ACTIVATE_STRATEGY_CALCULATIONS_DIRTY_FLAG, onStrategyCalculationsDirtyFlag);
}

export function* watchPositionCalculationsDirtyFlag() {
    yield* takeLatest(ACTIVATE_POSITIONS_CALCULATIONS_DIRTY_FLAG, onPositionsCalculationsDirtyFlag);
}

/**
 * The strategy saga layer is responsable for handling side effects when the data from the strategy state is affected
 */
export const strategySaga = function* root() {
    yield* all([
        fork(watchStrategyCalculationsDirtyFlag),
        fork(watchPlaceOrderRequest),
        fork(watchModifyOrderRequest),
        fork(watchClosePosition),
        fork(watchCancelOrderRequest),
        fork(watchWithdrawStrategyRequest),
        fork(watchUserTradesRequest),
        fork(watchPaymentsHistoryRequest),
        fork(watchEthAddress),
        fork(watchActiveStrategy),
        fork(watchPositionCalculationsDirtyFlag),
    ]);
};
