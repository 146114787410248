import { AddIcon, CloseIcon, InfoIcon } from '@chakra-ui/icons';
import {
    Alert,
    AlertIcon,
    Box,
    Button,
    chakra,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    IconButton,
    Input,
    Text,
    Tooltip,
} from '@chakra-ui/react';
import { UIStrategy } from '@derivadex/types';
import { getFrontendLogger } from '@derivadex/utils';
import { useScreenSize } from 'hooks/useScreenSize';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { getRuntimeConfig } from 'store/config/selectors';
import { getStrategiesAsList } from 'store/strategy/selectors';
import { CREATE_STRATEGY, SET_ACTIVE_STRATEGY } from 'store/strategy/slice';
import { isStrategiesDialogActive } from 'store/ui/selectors';
import { TOGGLE_STRATEGIES_DIALOG } from 'store/ui/slice';
import { getTwentyOneByteEthAddress } from 'store/web3/selectors';

import StrategiesTable from './StrategiesTable';

export default function StrategiesDropdown() {
    const { t } = useTranslation();
    const isActive = useSelector(isStrategiesDialogActive);
    const strategies = useSelector(getStrategiesAsList);
    const twentyOneByteTraderAddress = useSelector(getTwentyOneByteEthAddress);
    const dispatch = useDispatch();
    const [strategyName, setStrategyName] = useState<string>('');
    const [isAddStrategy, setIsAddStrategy] = useState<boolean>(false);
    const isDesktopView = useScreenSize();
    const runtimeConfig = useSelector(getRuntimeConfig);

    function selectStrategy(strategyId: string, twentyOneByteTraderAddress: string) {
        dispatch(TOGGLE_STRATEGIES_DIALOG());
        dispatch(SET_ACTIVE_STRATEGY({ strategyId, trader: twentyOneByteTraderAddress, isInitialLoad: false }));
    }

    function handleStrategyNameChange(event: any) {
        const value = event.target.value;
        const regex = RegExp('^[A-Za-z|0-9|-]+$');
        if (regex.test(value) && value.length <= 12) {
            setStrategyName(value);
        }
    }

    function createNewStrategy() {
        dispatch(CREATE_STRATEGY({ traderAddress: twentyOneByteTraderAddress!, strategyId: strategyName }));
        setStrategyName('');
        setIsAddStrategy(false);
    }

    function addStrategyElement() {
        return (
            <>
                <Input
                    ml={'0.25rem'}
                    placeholder="Strategy Name"
                    onChange={handleStrategyNameChange}
                    fontSize={isDesktopView ? '0.8rem' : '0.875rem'}
                    size={isDesktopView ? 'xs' : 'sm'}
                    value={strategyName}
                    inputMode="text"
                />
                <Button
                    ml={'0.25rem'}
                    size={isDesktopView ? 'xs' : 'sm'}
                    fontSize={isDesktopView ? '0.8rem' : '0.875rem'}
                    onClick={createNewStrategy}
                >
                    Create
                </Button>
            </>
        );
    }

    return (
        <Drawer
            isOpen={isActive}
            placement={isDesktopView ? 'left' : 'bottom'}
            onClose={() => dispatch(TOGGLE_STRATEGIES_DIALOG())}
        >
            <DrawerOverlay>
                <DrawerContent bgColor="card.100" id="drawer">
                    <DrawerCloseButton />
                    <DrawerHeader>{t('strategies')}</DrawerHeader>
                    <DrawerBody p="0.2rem">
                        <StrategiesTable isDesktopView={isDesktopView} />
                        <Tooltip label={t('createStrategy')} fontSize="1.5vh" placement="left">
                            <IconButton
                                size={isDesktopView ? 'sm' : 'xs'}
                                isDisabled={'false'}
                                fontSize={isDesktopView ? '0.8rem' : 'xs'}
                                icon={isAddStrategy ? <CloseIcon /> : <AddIcon />}
                                aria-label={'Add Strategy'}
                                onClick={() => setIsAddStrategy(!isAddStrategy)}
                                ml="0.5rem"
                            ></IconButton>
                        </Tooltip>
                        <Box mb="0.25rem" px="0.5rem" py="0.625rem" borderRadius="base">
                            <Flex align="center" direction="row">
                                {isAddStrategy ? addStrategyElement() : <></>}
                            </Flex>
                        </Box>
                        {isAddStrategy ? (
                            <Box>
                                <Alert status="warning" fontSize="0.8rem">
                                    <AlertIcon />
                                    {t('createStrategyInfo')}
                                </Alert>
                            </Box>
                        ) : (
                            <></>
                        )}
                    </DrawerBody>
                </DrawerContent>
            </DrawerOverlay>
        </Drawer>
    );
}
