import { hexUtils } from '@0x/utils';
import {
    AccountEventType,
    AccountMessage,
    Context,
    EnrollmentEvent,
    FeedEventType,
    ModifyOrderIntent,
    OneClickTradingStorageData,
    OperatorConfig,
    RequestFailure,
    RequestSequenced,
    RequestTransacted,
    RequestType,
    ResponseApi,
    ResponseStatus,
    TraderEvent,
    UIToastActionID,
    UIToastStatus,
    UIToastTitle,
    WithdrawDDXIntent,
} from '@derivadex/types';
import {
    createUpdateProfileIntentTypedData,
    createWithdrawDDXIntentTypedData,
    DerivadexSocket,
    getErrorMessage,
    getFrontendLogger,
    transformTypedDataForEthers,
} from '@derivadex/utils';
import { signMessage } from '@wagmi/core';
import { randomBytes } from 'crypto';
import * as ethers from 'ethers';
import i18n from 'i18next';
import { eventChannel } from 'redux-saga';
import { getOperatorConfig } from 'store/config/selectors';
import { waitFor } from 'store/saga';
import { getSocket } from 'store/socket/selectors';
import { ACTIVATE_PAYMENTS_HISTORY_DIRTY_FLAG, MODIFY_ORDER_INTENT, PLACE_ORDER_INTENT } from 'store/strategy/slice';
import { encryptIntent } from 'store/strategy/utils';
import {
    ADD_TOAST_MESSAGE,
    ProfileIntentUIState,
    SET_PROFILE_INTENT_UI_STATE,
    SET_WITHDRAW_INTENT_DDX_UI_STATE,
    WithdrawIntentDdxUIState,
} from 'store/ui/slice';
import { getEthAddress, getWeb3Context } from 'store/web3/selectors';
import { WITHDRAW_DDX_STATE_UPDATE_TRIGGER } from 'store/web3/slice';
import { all, call, fork, put, putResolve, take, takeLatest } from 'typed-redux-saga/macro';
import { action } from 'typesafe-actions';
import { OPERATOR_DECIMAL_MULTIPLIER } from 'utils/constants';
import { signTypedMessage } from 'utils/web3_api';

import { config } from '../../config';
import {
    SET_PROFILE,
    UPDATE_ENROLLMENT_STATUS,
    UPDATE_FAVORITE_TICKERS,
    UPDATE_FAVORITE_TICKERS_INTENT,
    UPDATE_ONE_CLICK_TRADING_INTENT,
    UPDATE_ONE_CLICK_TRADING_STATUS,
    UPDATE_PROFILE_INTENT,
    WITHDRAW_TRADER_INTENT,
} from './slice';

function* handleProfileFeedUpdate(payload: TraderEvent): Generator {
    try {
        if (payload.e === FeedEventType.PARTIAL) {
            const trader = payload.c;
            yield* putResolve(SET_PROFILE(trader));
        } else if (payload.e === FeedEventType.UPDATE) {
            const trader = payload.c;
            yield* putResolve(SET_PROFILE(trader));
            yield* putResolve(ACTIVATE_PAYMENTS_HISTORY_DIRTY_FLAG());
        }
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in trader data success', action);
        getFrontendLogger().logError(getErrorMessage(error));
    }
}

function* handleEnrollmentUpdate(payload: EnrollmentEvent): Generator {
    try {
        if (payload.e === FeedEventType.PARTIAL) {
            const status = payload.c.status;
            yield* putResolve(UPDATE_ENROLLMENT_STATUS(status));
        } else if (payload.e === FeedEventType.UPDATE) {
            const status = payload.c.status;
            yield* putResolve(UPDATE_ENROLLMENT_STATUS(status));
        }
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in trader data success', action);
        getFrontendLogger().logError(getErrorMessage(error));
    }
}

export function* handleProfile(socket: DerivadexSocket) {
    try {
        const channel = yield* call(getProfileFeedEventChannel, socket);
        while (true) {
            const message = yield* take(channel);
            switch (message.t) {
                case AccountEventType.TRADER:
                    yield* call(handleProfileFeedUpdate, message);
                    break;
                case AccountEventType.ENROLLMENT:
                    yield* call(handleEnrollmentUpdate, message);
                    break;
                default:
                    break;
            }
        }
    } catch (error) {
        getFrontendLogger().logError(error);
    }
}

export function getProfileFeedEventChannel(socket: DerivadexSocket) {
    return eventChannel<AccountMessage>((emitter) => {
        socket.onAccountUpdate((value) => emitter(value));
        return () => {
            socket.close();
        };
    });
}

function* onUpdateFavoriteTickersRequest(action: ReturnType<typeof UPDATE_FAVORITE_TICKERS_INTENT.request>): Generator {
    const ticker = action.payload.ticker;
    const include = action.payload.include;
    try {
        yield* putResolve(UPDATE_FAVORITE_TICKERS({ ticker, include }));
    } catch (error: any) {
        getFrontendLogger().logError(getErrorMessage(error));
    }
}

function* onUpdateOneClickTradingRequest(
    action: ReturnType<typeof UPDATE_ONE_CLICK_TRADING_INTENT.request>,
): Generator {
    const enabled = action.payload.enabled;
    try {
        if (enabled) {
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    status: UIToastStatus.Pending,
                    title: i18n.t('confirmTransaction'),
                    description: i18n.t('waitingForWalletConfirmation'),
                    actionID: UIToastActionID.OneClickTradingUpdate,
                }),
            );
        }
        const traderAddress = yield* call(waitFor<string>, getEthAddress);
        const data: OneClickTradingStorageData = {
            trader: traderAddress,
            enabled: enabled,
            sessionPrivateKey: '',
            signature: '',
        };
        if (enabled) {
            const sessionData = yield* call(generateOneClickTradingSession);
            if (sessionData) {
                data.sessionPrivateKey = sessionData.sessionPrivateKey;
                data.signature = sessionData.signature;
                yield* putResolve(
                    ADD_TOAST_MESSAGE({
                        status: UIToastStatus.Successful,
                        title: i18n.t('confirmTransaction'),
                        description: i18n.t('updateProfileSuccess'),
                        actionID: UIToastActionID.OneClickTradingUpdate,
                    }),
                );
                yield* put(UPDATE_ONE_CLICK_TRADING_STATUS({ data }));
            } else {
                yield* putResolve(
                    ADD_TOAST_MESSAGE({
                        title: UIToastTitle.Update_Profile,
                        status: UIToastStatus.Failed,
                        description: i18n.t('updateProfileRejected'),
                        actionID: UIToastActionID.OneClickTradingUpdate,
                    }),
                );
                data.enabled = false;
                yield* put(UPDATE_ONE_CLICK_TRADING_STATUS({ data }));
            }
        } else {
            yield* put(UPDATE_ONE_CLICK_TRADING_STATUS({ data }));
        }
    } catch (error: any) {
        getFrontendLogger().logError(getErrorMessage(error));
        yield* put(UPDATE_ONE_CLICK_TRADING_INTENT.failure(error));
        yield* putResolve(
            ADD_TOAST_MESSAGE({
                title: UIToastTitle.Update_Profile,
                status: UIToastStatus.Failed,
                description: i18n.t('updateProfileRejected'),
                actionID: UIToastActionID.OneClickTradingUpdate,
            }),
        );
    } finally {
        const orderIntent = action.payload.orderIntent;
        if (orderIntent) {
            if (action.payload.isModifyOrder) {
                const modifyOrderIntent = {
                    traderAddress: orderIntent.traderAddress,
                    symbol: orderIntent.symbol,
                    strategy: orderIntent.strategy,
                    side: orderIntent.side,
                    orderType: orderIntent.orderType,
                    nonce: orderIntent.nonce,
                    amount: orderIntent.amount,
                    price: orderIntent.price,
                    stopPrice: orderIntent.stopPrice,
                    signature: orderIntent.signature,
                    orderHash: orderIntent.orderHash,
                    sessionKeySignature: orderIntent.sessionKeySignature,
                } as ModifyOrderIntent;
                yield* putResolve(MODIFY_ORDER_INTENT.request(modifyOrderIntent));
            } else {
                yield* putResolve(PLACE_ORDER_INTENT.request(orderIntent));
            }
        }
    }
}

async function generateOneClickTradingSession(): Promise<{
    sessionPrivateKey: string;
    signature: string;
} | null> {
    try {
        // Generate a secp256k1 key pair by creating a wallet with a randomly generated private key
        const sessionPrivateKeyBytes = randomBytes(32);
        const sessionPrivateKey = hexUtils.toHex(sessionPrivateKeyBytes);
        const sessionWallet = new ethers.Wallet(sessionPrivateKey);
        const sessionPublicKey = sessionWallet.publicKey;
        // request wallet to sign the public key (sign the data representation so that we can recover signer from bytes array)
        const signature = await signMessage(config, { message: sessionPublicKey });
        return { sessionPrivateKey, signature };
    } catch (error) {
        getFrontendLogger().logError(getErrorMessage(error));
        return null;
    }
}

function* onUpdateProfileRequest(action: ReturnType<typeof UPDATE_PROFILE_INTENT.request>): Generator {
    try {
        yield* putResolve(SET_PROFILE_INTENT_UI_STATE(ProfileIntentUIState.PENDING_WALLET_CONFIRMATION));
        yield* putResolve(
            ADD_TOAST_MESSAGE({
                status: UIToastStatus.Pending,
                title: i18n.t('confirmTransaction'),
                description: i18n.t('waitingForWalletConfirmation'),
                actionID: UIToastActionID.ProfileUpdate,
            }),
        );
        const socket = yield* call(waitFor<DerivadexSocket>, getSocket);
        const context = yield* call(waitFor<Context>, getWeb3Context);
        const traderAddress = yield* call(waitFor<string>, getEthAddress);
        const updateProfile = action.payload;
        const updateProfileTyped = yield* call(
            createUpdateProfileIntentTypedData,
            updateProfile,
            context.chainId,
            context.deployment,
            context.contractAddresses.derivaDEXAddress,
        );
        const typedDataForEthers = yield* call(transformTypedDataForEthers, updateProfileTyped);
        const signature = yield* call(signTypedMessage, typedDataForEthers);
        updateProfile.signature = signature;
        const intent = { t: 'ProfileUpdate', c: updateProfile };
        const operatorConfig = yield* call(waitFor<OperatorConfig>, getOperatorConfig);
        const encryptedIntent: string = yield* call(encryptIntent, operatorConfig.encryptionKey, intent, null);
        const response: ResponseApi<RequestSequenced | RequestTransacted | RequestFailure> = yield* call(
            [socket, socket.submitIntent],
            {
                t: RequestType.PROFILE_UPDATE_INTENT,
                c: { traderAddress, encryptedIntent },
            },
        );
        if (response.e === ResponseStatus.SUCCESS) {
            yield* putResolve(UPDATE_PROFILE_INTENT.success(response.c as RequestSequenced));
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    title: UIToastTitle.Update_Profile,
                    status: UIToastStatus.Successful,
                    description: i18n.t('updateProfileSuccess'),
                    actionID: UIToastActionID.ProfileUpdate,
                }),
            );
        } else {
            const failureResponse = response.c as RequestFailure;
            yield* putResolve(UPDATE_PROFILE_INTENT.failure(failureResponse));
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    title: UIToastTitle.Update_Profile,
                    status: UIToastStatus.Failed,
                    description: i18n.t('updateProfileFailure'),
                    actionID: UIToastActionID.ProfileUpdate,
                }),
            );
            getFrontendLogger().logError(
                `System malfunction: Request Failure for Submit ProfileUpdate Intent (${failureResponse.c.inner} | ${failureResponse.c.message})`,
            );
        }
    } catch (error: any) {
        getFrontendLogger().logError(getErrorMessage(error));
        yield* put(UPDATE_PROFILE_INTENT.failure(error));
        yield* putResolve(
            ADD_TOAST_MESSAGE({
                title: UIToastTitle.Update_Profile,
                status: UIToastStatus.Failed,
                description: i18n.t('updateProfileRejected'),
                actionID: UIToastActionID.ProfileUpdate,
            }),
        );
    } finally {
        yield* putResolve(SET_PROFILE_INTENT_UI_STATE(ProfileIntentUIState.NONE));
    }
}

export function* watchUpdateProfileRequest() {
    yield* takeLatest(UPDATE_PROFILE_INTENT.request, onUpdateProfileRequest);
}

function* onWithdrawTraderRequest(action: ReturnType<typeof WITHDRAW_TRADER_INTENT.request>): Generator {
    yield* putResolve(SET_WITHDRAW_INTENT_DDX_UI_STATE(WithdrawIntentDdxUIState.PENDING_WALLET_CONFIRMATION));
    yield* putResolve(
        ADD_TOAST_MESSAGE({
            status: UIToastStatus.Pending,
            title: i18n.t('confirmTransaction'),
            description: i18n.t('waitingForWalletConfirmation'),
            actionID: UIToastActionID.WithdrawDDX,
        }),
    );

    try {
        const socket = yield* call(waitFor<DerivadexSocket>, getSocket);
        const context = yield* call(waitFor<Context>, getWeb3Context);
        const traderAddress = yield* call(waitFor<string>, getEthAddress);
        const scaledWithdrawIntent: WithdrawDDXIntent = {
            ...action.payload,
            amount: action.payload.amount.multipliedBy(OPERATOR_DECIMAL_MULTIPLIER),
        };
        const scaledWithdrawIntentTyped = yield* call(
            createWithdrawDDXIntentTypedData,
            scaledWithdrawIntent,
            context.chainId,
            context.deployment,
            context.contractAddresses.derivaDEXAddress,
        );
        const typedDataForEthers = yield* call(transformTypedDataForEthers, scaledWithdrawIntentTyped);
        const signature = yield* call(signTypedMessage, typedDataForEthers);
        action.payload.signature = signature;
        const intent = { t: 'WithdrawDDX', c: action.payload };
        const operatorConfig = yield* call(waitFor<OperatorConfig>, getOperatorConfig);
        const encryptedIntent: string = yield* call(encryptIntent, operatorConfig.encryptionKey, intent, null);
        yield* putResolve(SET_WITHDRAW_INTENT_DDX_UI_STATE(WithdrawIntentDdxUIState.IN_PROGRESS));
        const response: ResponseApi<RequestSequenced | RequestTransacted | RequestFailure> = yield* call(
            [socket, socket.submitIntent],
            {
                t: RequestType.WITHDRAW_STRATEGY_INTENT,
                c: { traderAddress, encryptedIntent },
            },
        );
        if (response.e === ResponseStatus.SUCCESS) {
            yield* putResolve(WITHDRAW_TRADER_INTENT.success(response.c as RequestSequenced));
            yield* putResolve(WITHDRAW_DDX_STATE_UPDATE_TRIGGER());
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    title: UIToastTitle.Withdraw_DDX,
                    status: UIToastStatus.Successful,
                    description: i18n.t('withdrawDDXInitiateSuccess'),
                    actionID: UIToastActionID.WithdrawDDX,
                }),
            );
        } else {
            const failureResponse = response.c as RequestFailure;
            yield* putResolve(WITHDRAW_TRADER_INTENT.failure(failureResponse));
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    title: UIToastTitle.Withdraw_DDX,
                    status: UIToastStatus.Failed,
                    description: failureResponse.c.inner,
                    actionID: UIToastActionID.WithdrawDDX,
                }),
            );
            getFrontendLogger().logError(
                `System malfunction: Request Failure for Submit WithdrawDDX Intent (${failureResponse.c.inner} | ${failureResponse.c.message})`,
            );
        }
    } catch (error: any) {
        getFrontendLogger().logError(getErrorMessage(error));
        yield* put(WITHDRAW_TRADER_INTENT.failure(error));
        yield* putResolve(
            ADD_TOAST_MESSAGE({
                status: UIToastStatus.Failed,
                title: UIToastTitle.Withdraw_DDX,
                description: i18n.t('initateWithdrawalRejected'),
                actionID: UIToastActionID.WithdrawDDX,
            }),
        );
    } finally {
        yield* putResolve(SET_WITHDRAW_INTENT_DDX_UI_STATE(WithdrawIntentDdxUIState.NONE));
    }
}

export function* watchUpdateFavoriteTickersRequest() {
    yield* takeLatest(UPDATE_FAVORITE_TICKERS_INTENT.request, onUpdateFavoriteTickersRequest);
}

export function* watchUpdateOneClickTradingRequest() {
    yield* takeLatest(UPDATE_ONE_CLICK_TRADING_INTENT.request, onUpdateOneClickTradingRequest);
}

export function* watchWithdrawTraderRequest() {
    yield* takeLatest(WITHDRAW_TRADER_INTENT.request, onWithdrawTraderRequest);
}

/**
 * The trader saga layer is responsable for handling side effects when the data from the trader state is affected
 */
export const profileSaga = function* root() {
    yield* all([
        fork(watchUpdateProfileRequest),
        fork(watchUpdateOneClickTradingRequest),
        fork(watchUpdateFavoriteTickersRequest),
        fork(watchWithdrawTraderRequest),
    ]);
};
