import { ActionType, FundingRateComparisonUIData } from '@derivadex/types';
import { getErrorMessage, getFrontendLogger } from '@derivadex/utils';
import { getStatsApiAggregationsUrl, getStatsApiUrl } from 'store/config/selectors';
import { buildUrl, makeRequest, parsefundingComparisonResponse, parseOpenInterestResponse } from 'store/requestUtils';
import { UIViewState, UPDATE_CURRENT_VIEW } from 'store/ui/slice';
import { all, call, fork, putResolve, select, takeLatest } from 'typed-redux-saga/macro';

import { FETCH_FUNDING_DATA, SET_FUNDING_COMPARISON_DATA } from './slice';

function* handleFetchComparisonData(action: ReturnType<typeof FETCH_FUNDING_DATA>): Generator {
    yield* putResolve(UPDATE_CURRENT_VIEW({ view: UIViewState.FUNDING_COMPARISON }));
    try {
        const statsApiAggregationsUrl = yield* select(getStatsApiAggregationsUrl);
        const aggregationsUrl = buildUrl(statsApiAggregationsUrl, undefined, 'funding_rate_comparison', {});
        const fundingComparisonResponse = yield* call(makeRequest, aggregationsUrl);
        const parsedFundingComparisonData = yield* call(parsefundingComparisonResponse, fundingComparisonResponse);
        getFrontendLogger().log('funding comparison parsed response', parsedFundingComparisonData);

        // If we have funding data, proceed with open interest lookup
        if (parsedFundingComparisonData && parsedFundingComparisonData.length > 0) {
            // Extract unique symbols from the funding data
            const uniqueSymbols = Array.from(new Set(parsedFundingComparisonData.map((item) => item.symbol)));
            // Create a map to store open interest data by symbol
            const openInterestBySymbol: Record<string, any> = {};

            // Fetch open interest for each unique symbol
            for (const symbol of uniqueSymbols) {
                // Append 'P' to the symbol for DerivaDEX format
                const derivadexSymbol = `${symbol}P`;

                const statsApiUrl = yield* select(getStatsApiUrl);
                const openInterestUrl = buildUrl(statsApiUrl, undefined, 'open_interest_history', {
                    order: 'desc',
                    symbol: derivadexSymbol,
                    limit: 1,
                });
                try {
                    const openInterestResponse = yield* call(makeRequest, openInterestUrl);
                    const parsedOpenInterestData = yield* call(parseOpenInterestResponse, openInterestResponse);
                    if (parsedOpenInterestData && parsedOpenInterestData.length > 0) {
                        // Store the first (most recent) open interest entry for this symbol
                        openInterestBySymbol[symbol] = parsedOpenInterestData[0];
                    }
                } catch (openInterestError) {
                    getFrontendLogger().logError(
                        `Error fetching open interest for ${derivadexSymbol}`,
                        getErrorMessage(openInterestError),
                    );
                }
            }
            // add open interst to the funding comparison data by corresponding symbols
            const fundingDataWithOpenInterest: FundingRateComparisonUIData[] = parsedFundingComparisonData.map(
                (item) => {
                    const symbol = item.symbol;
                    const openInterest = openInterestBySymbol[symbol] ? openInterestBySymbol[symbol].amount : 0;
                    return {
                        ...item,
                        openInterest,
                    };
                },
            );

            // Update the state with enhanced data
            yield* putResolve(
                SET_FUNDING_COMPARISON_DATA({
                    fundingComparisonResponse:
                        fundingDataWithOpenInterest && fundingDataWithOpenInterest.length > 0
                            ? fundingDataWithOpenInterest
                            : (parsedFundingComparisonData as FundingRateComparisonUIData[]),
                }),
            );
        }
    } catch (error: any) {
        getFrontendLogger().logError('caught exception while fetching funding comparison data', getErrorMessage(error));
    }
}

export function* watchFundingComparisonData() {
    yield* takeLatest(ActionType.FETCH_FUNDING_COMPARISON, handleFetchComparisonData);
}

/**
 * The funding saga layer is responsable for handling side effects for the funding comparison page
 */
export const fundingComparisonSaga = function* root() {
    yield* all([fork(watchFundingComparisonData)]);
};
