import { BigNumber } from '@0x/utils';
import { Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import NumberFormatMinimumValue from 'components/Marketplace/OrderTables/NumberFormatMinimumValue';
import Table from 'components/Table/Table';
import TooltipWrapper from 'components/Tooltip/TooltipWrapper';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getMarkPrices } from 'store/market/selectors';

export default function FundingComparisonTable({ isDesktopView, data }: { isDesktopView: boolean; data: any[] }) {
    const { t } = useTranslation();
    const columnHelper = createColumnHelper<any>();
    const markPrices = useSelector(getMarkPrices);

    const columns = useMemo(
        () => [
            columnHelper.accessor('symbol', {
                cell: (info) => <Text>{info.getValue()}</Text>,
                header: 'Market',
            }),
            columnHelper.accessor('openInterest', {
                cell: (info) =>
                    markPrices[info.row.original.symbol + 'P'] ? (
                        <NumberFormatMinimumValue
                            value={
                                markPrices[info.row.original.symbol + 'P'] &&
                                markPrices[info.row.original.symbol + 'P'].price
                                    ? markPrices[info.row.original.symbol + 'P'].price.multipliedBy(
                                          new BigNumber(info.getValue()),
                                      )
                                    : null
                            }
                            useCurrencyPrefix={true}
                        />
                    ) : (
                        <Text>
                            {new BigNumber(info.getValue()).decimalPlaces(2).toString()} {info.row.original.symbol}
                        </Text>
                    ),
                header: 'DerivaDEX Open Interest',
            }),
            columnHelper.accessor('derivadexFundingRate', {
                cell: (info) => <Text>{info.getValue()}</Text>,
                header: 'DerivaDEX',
            }),
            columnHelper.accessor('binanceFundingRate', {
                cell: (info) => <Text>{info.getValue()}</Text>,
                header: 'Binance',
            }),
            columnHelper.accessor('derivadexBinanceArbitrage', {
                cell: (info) => (
                    <TooltipWrapper
                        hasArrow
                        placement="left"
                        label={getFundingRateArbitrageLabel(
                            info.row.original.derivadexFundingRate,
                            info.row.original.binanceFundingRate,
                            new BigNumber(info.getValue()),
                            info.row.original.symbol,
                            'Binance',
                        )}
                    >
                        <Text color={info.getValue() < 0 ? '#f04351' : '#70c6a3'}>
                            {new BigNumber(info.getValue()).decimalPlaces(5).toString()}
                        </Text>
                    </TooltipWrapper>
                ),
                header: 'Binance-DerivaDEX Arbitrage',
            }),
            columnHelper.accessor('bybitFundingRate', {
                cell: (info) => <Text>{info.getValue()}</Text>,
                header: 'Bybit',
            }),
            columnHelper.accessor('derivadexBybitArbitrage', {
                cell: (info) => (
                    <TooltipWrapper
                        hasArrow
                        placement="left"
                        label={getFundingRateArbitrageLabel(
                            info.row.original.derivadexFundingRate,
                            info.row.original.bybitFundingRate,
                            new BigNumber(info.getValue()),
                            info.row.original.symbol,
                            'Bybit',
                        )}
                    >
                        <Text color={info.getValue() < 0 ? '#f04351' : '#70c6a3'}>
                            {new BigNumber(info.getValue()).decimalPlaces(5).toString()}
                        </Text>
                    </TooltipWrapper>
                ),
                header: 'Bybit-DerivaDEX Arbitrage',
            }),
        ],
        [markPrices],
    );

    return (
        <div className="funding-comparison">
            <Table isDesktopView={isDesktopView} columns={columns} data={data} emptyText={t('noData')} sortBy={[]} />
        </div>
    );
}

function getFundingRateArbitrageLabel(
    derivadex: number,
    other: number,
    fundingRatedelta: BigNumber,
    symbol: string,
    source: string,
) {
    if (fundingRatedelta.isZero()) {
        return `No funding arbitrage available between DerivaDEX and ${source}`;
    }

    if (derivadex < other) {
        return (
            <>
                {`Long ${symbol} on DerivaDEX and short on ${source}.`}
                <br />
                {`This strategy has an Annualized Rate of Return of ${fundingRatedelta
                    .multipliedBy(365 * 3)
                    .toFixed(2)}%`}
            </>
        );
    } else {
        return (
            <>
                {`Short ${symbol} on DerivaDEX and long on ${source}.`}
                <br />
                {`This strategy has an Annualized Rate of Return of ${fundingRatedelta
                    .multipliedBy(365 * 3)
                    .toFixed(2)}%`}
            </>
        );
    }
}
