import { UIDowntimeCode, UIDowntimeState } from '@derivadex/types';
import { ConnectionError, DerivadexSocket, getFrontendLogger } from '@derivadex/utils';
import * as Sentry from '@sentry/react';
import { initialRuntimeConfig } from 'config/runtimeConfig';
import { shouldUseExternalAnalytics } from 'logging/sentry';
import { isConsoleLoggingEnabled } from 'logging/utils';
import createSagaMiddleware, { eventChannel } from 'redux-saga';
import { all, call, delay, fork, put, putResolve, select, take } from 'typed-redux-saga/macro';

import { handleConfig } from './config/saga';
import { configSlices } from './config/slice';
import { fundingComparisonSaga } from './funding/saga';
import { leaderboardSaga } from './leaderboard/saga';
import { handleMarket, marketSaga } from './market/saga';
import { portfolioStatsSaga } from './portfolio/sagaStats';
import { portfolioTablesSaga } from './portfolio/sagaTables';
import { handleProfile, profileSaga } from './profile/saga';
import { AppState } from './slices';
import { SET_SOCKET } from './socket/slice';
import { handleStrategy, strategySaga } from './strategy/saga';
import { handleTransaction, transactionSaga } from './transactions/saga';
import { uiSaga } from './ui/saga';
import { SET_DOWNTIME } from './ui/slice';
import { web3Saga } from './web3/saga';
import { SET_CONTEXT } from './web3/slice';

const RECONNECT_INTERVAL_MS = 3000;

export function getDerivadexSocket() {
    const protocol = document.location.protocol.replace(/^http/, 'ws');
    const location = `${protocol}//${document.location.host}/v2/api`;
    // A blank REACT_RUNTIME_WS_API_URL setting will resolve to the current hostname
    const WS_URL = initialRuntimeConfig.WS_API_URL || location;
    getFrontendLogger().log('Connecting to endpoint: ', WS_URL);
    return new DerivadexSocket(WS_URL);
}

export function* waitFor<T>(selector: (state: AppState) => T | undefined) {
    while (true) {
        const value = yield* select(selector);
        if (value !== undefined) {
            return value as T;
        }
        yield* take('*');
    }
}

export function* waitUntilTrue(selector: (state: AppState) => boolean) {
    while (true) {
        const value = yield* select(selector);
        if (value === true) {
            return value;
        }
        yield* take('*');
    }
}

export function getConnectionErrorEventChannel(socket: DerivadexSocket) {
    return eventChannel<ConnectionError>((emitter) => {
        socket.onConnectionError((value) => emitter(value));
        return () => {
            socket.close();
        };
    });
}

export function* handleSocketConnectionError(socket: DerivadexSocket) {
    const channel = yield* call(getConnectionErrorEventChannel, socket);
    while (true) {
        try {
            const message = yield* take(channel);
            getFrontendLogger().logError(
                `Socket connection error ${JSON.stringify(
                    message,
                )} | Reconnections ${socket.totalReconnectionAttempts()}`,
            );
            if (message.c.code === 1006) {
                yield* delay(RECONNECT_INTERVAL_MS);
                yield* fork(connectSocket, socket);
            } else if (message.c.code === 1013) {
                yield* putResolve(
                    SET_DOWNTIME({ message: UIDowntimeState.HEAVY_LOAD, code: UIDowntimeCode.SOCKET_LOAD_FAILED }),
                );
            } else {
                getFrontendLogger().logError(`Unexpected socket connection error ${JSON.stringify(message)}`);
                yield* putResolve(
                    SET_DOWNTIME({
                        message: UIDowntimeState.PROBLEM_OCCURRED,
                        code: UIDowntimeCode.SOCKET_LOAD_FAILED,
                    }),
                );
            }
        } catch (e) {
            getFrontendLogger().logError(`Error in Socket connection error handler ${JSON.stringify(e)}`);
            yield* putResolve(
                SET_DOWNTIME({ message: UIDowntimeState.PROBLEM_OCCURRED, code: UIDowntimeCode.SOCKET_LOAD_FAILED }),
            );
        }
    }
}

export function* connectSocket(socket: DerivadexSocket) {
    try {
        const context = yield* call([socket, socket.connect]);
        yield* put(configSlices.UPDATE_EPOCH_CONFIG(context.epochConfig));
        yield* put(SET_CONTEXT(context));
    } catch (error) {
        getFrontendLogger().logError(error);
        yield* putResolve(
            SET_DOWNTIME({ message: UIDowntimeState.PROBLEM_OCCURRED, code: UIDowntimeCode.SOCKET_LOAD_FAILED }),
        );
    }
}

export function* topLevelSocketListener() {
    try {
        // Immediately subscribe to socket since it doesn't require
        // any data to establish the connection
        const derivadexSocket = yield* call(getDerivadexSocket);
        // set up handlers for IO *before* connection established so they dont miss events
        yield* fork(handleSocketConnectionError, derivadexSocket);
        yield* fork(handleConfig, derivadexSocket);
        yield* fork(handleMarket, derivadexSocket);
        yield* fork(handleStrategy, derivadexSocket);
        yield* fork(handleProfile, derivadexSocket);
        yield* fork(handleTransaction, derivadexSocket);
        yield* delay(100);
        // Connect the socket
        yield* call(connectSocket, derivadexSocket);
        // save the socket to the store, where it can be retrieved for chart library later
        yield* put(SET_SOCKET(derivadexSocket));
    } catch (error) {
        getFrontendLogger().logError(error);
    }
}

export const sagaMiddleware = createSagaMiddleware({
    context: {},
    onError: (error: any) => {
        if (!isConsoleLoggingEnabled() && shouldUseExternalAnalytics()) {
            Sentry.captureException(error);
        } else {
            getFrontendLogger().logError(error);
        }
    },
});

export function* rootSaga() {
    yield* all([
        fork(marketSaga),
        fork(transactionSaga),
        fork(web3Saga),
        fork(strategySaga),
        fork(profileSaga),
        fork(uiSaga),
        fork(portfolioStatsSaga),
        fork(portfolioTablesSaga),
        fork(leaderboardSaga),
        fork(fundingComparisonSaga),
        fork(topLevelSocketListener),
    ]);
}
