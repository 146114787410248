import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { TransformedFundingRateItem } from '@derivadex/types';
import FundingComparisonTable from 'components/FundingComparison/FundingComparisonTable';
import Layout from 'components/Layout';
import { useScreenSize } from 'hooks/useScreenSize';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getFundingComparisonData } from 'store/funding/selectors';
import { FETCH_FUNDING_DATA } from 'store/funding/slice';

export default function FundingComparison() {
    const dispatch = useDispatch();
    const isDesktopView = useScreenSize();
    const { t } = useTranslation();
    const fundingComparisonData: TransformedFundingRateItem[] | undefined = useSelector(getFundingComparisonData);
    useEffect(() => {
        dispatch(
            FETCH_FUNDING_DATA({
                symbol: undefined,
            }),
        );
    }, []);

    return (
        <Layout isDesktopView={isDesktopView}>
            {isDesktopView ? (
                <Flex direction={'column'} py="3rem" px="5rem">
                    <Heading fontSize={{ base: '2.2vh', lg: '2.7vh' }}>Funding Rate Comparison</Heading>
                    <Heading fontSize={{ base: '1vh', lg: '1.5vh' }} mt="0.5rem">
                        Compare funding rates across exchanges
                    </Heading>
                    <Box p="0.5rem" pt="3rem" width={'90%'} height={'80%'}>
                        <FundingComparisonTable
                            isDesktopView={true}
                            data={fundingComparisonData}
                        ></FundingComparisonTable>
                    </Box>
                    <Text fontSize={'xs'}>*{t('fundingComparisonUpdateText')}</Text>
                </Flex>
            ) : (
                <Flex mt="0.5rem" mb="0.5rem" direction="column" gap="0.5rem" h="100%" w="100%">
                    <Heading fontSize={{ base: '2.2vh', lg: '2.7vh' }}>Funding Rate Comparison</Heading>
                    <Box
                        p="0.5rem"
                        overflowY="scroll"
                        sx={{
                            '::-webkit-scrollbar': {
                                display: 'none',
                            },
                            scrollbarWidth: 'none',
                        }}
                    >
                        <FundingComparisonTable
                            isDesktopView={true}
                            data={fundingComparisonData}
                        ></FundingComparisonTable>
                    </Box>
                    <Text fontSize={'xs'}>*{t('fundingComparisonUpdateText')}</Text>
                </Flex>
            )}
        </Layout>
    );
}
