import { BigNumber } from '@0x/utils';
import { Button } from '@chakra-ui/react';
import { ModifyOrderIntent, OrderIntent, OrderSide, OrderType } from '@derivadex/types';
import { as_nonce, getErrorMessage, getFrontendLogger } from '@derivadex/utils';
import { useScreenSize } from 'hooks/useScreenSize';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedMarket } from 'store/market/selectors';
import { getOneClickTradingOptOutLocalStorage, getOneClickTradingStatus } from 'store/profile/selectors';
import { getSelectedStrategyAvailableCollateral, getSelectedStrategyId } from 'store/strategy/selectors';
import { MODIFY_ORDER_INTENT, PLACE_ORDER_INTENT } from 'store/strategy/slice';
import { getModifyOrderUIState, getPlaceOrderUIState, isOmfLessImf } from 'store/ui/selectors';
import {
    ModifyOrderUIState,
    OneClickTradingIntentUIState,
    PlaceOrderUIState,
    SET_MODIFY_ORDER_UI_STATE,
    SET_ONE_CLICK_TRADING_INTENT_UI_STATE,
    SET_PLACE_ORDER_UI_STATE,
    UPDATE_OMF_LESS_IMF,
} from 'store/ui/slice';
import { getEthAddress } from 'store/web3/selectors';

import { Intent } from './OrderForm';
import SubmitModifyOrder from './SubmitModifyOrder';
import SubmitOrder from './SubmitOrder';

interface IProps {
    isModifyOrder: boolean;
    isValidModifyOrder: boolean;
    isConnected: boolean;
    orderSide: OrderSide;
    orderType: OrderType;
    selectedOrder: OrderIntent | null;
    leverageState: Intent | null;
    amount: string;
    price: string;
    setUserAlreadyTouched: Dispatch<SetStateAction<boolean>>;
    setPrice: Dispatch<SetStateAction<string>>;
    setAmount: Dispatch<SetStateAction<string>>;
    setLeverage: Dispatch<SetStateAction<number>>;
    checkboxes: {
        postOnly: boolean;
    };
}

export default function ConfirmOrderButton({
    isModifyOrder,
    isValidModifyOrder,
    isConnected,
    orderSide,
    orderType,
    selectedOrder,
    leverageState,
    amount,
    price,
    setUserAlreadyTouched,
    setPrice,
    setAmount,
    setLeverage,
    checkboxes,
}: IProps) {
    const { t } = useTranslation();
    const isDesktopView = useScreenSize();
    const [orderIntent, setOrderIntent] = useState<OrderIntent | undefined>(undefined);
    const dispatch = useDispatch();
    const placeOrderUiState = useSelector(getPlaceOrderUIState);
    const modifyOrderUiState = useSelector(getModifyOrderUIState);
    const ethAddress = useSelector(getEthAddress);
    const strategyId = useSelector(getSelectedStrategyId);
    const currentStrategyAvailableCollateral = useSelector(getSelectedStrategyAvailableCollateral);
    const checkOmfLessImf = useSelector(isOmfLessImf);
    const market = useSelector(getSelectedMarket);

    const isAmountEmpty = amount === null || amount === '' || Number(amount) === 0;
    const isPriceEmpty = price === null || price === '' || Number(price) === 0;
    const orderTypeLimitIsEmpty = isAmountEmpty || isPriceEmpty;

    const isOneCLickTradingEnabled = useSelector(getOneClickTradingStatus);
    const disableOneClickTradingPrompt = useSelector(getOneClickTradingOptOutLocalStorage);

    function getConfirmButtonLabel() {
        if (orderSide === OrderSide.Bid) {
            return isModifyOrder ? `${t('modifyBuy')}` : `${t('buy')}`;
        } else {
            return isModifyOrder ? `${t('modifySell')}` : `${t('sell')}`;
        }
    }

    useEffect(() => {
        if (amount !== '') {
            const potentialOpenOrder = {
                side: orderSide,
                size: new BigNumber(amount === '' ? 0 : amount),
                remainingAmount: new BigNumber(amount === '' ? 0 : amount),
                price: new BigNumber(price === '' ? 0 : price),
                symbol: 'ETHP',
            };
            dispatch(UPDATE_OMF_LESS_IMF(potentialOpenOrder));
        }
    }, [amount, price, orderSide, currentStrategyAvailableCollateral]);

    const createOrder = async (orderSide: OrderSide, orderType: OrderType) => {
        if (
            ethAddress === undefined ||
            market === undefined ||
            new BigNumber(currentStrategyAvailableCollateral || 0).isZero()
        ) {
            return;
        }
        const orderIntent: OrderIntent = {
            traderAddress: ethAddress,
            symbol: market.symbol,
            strategy: strategyId,
            side: orderSide,
            orderType:
                orderType === OrderType.Limit
                    ? checkboxes.postOnly === true
                        ? OrderType.LimitPostOnly
                        : OrderType.Limit
                    : orderType,
            nonce: as_nonce(Date.now()),
            amount: new BigNumber(amount),
            price: orderType === OrderType.Limit ? new BigNumber(price) : new BigNumber(0),
            stopPrice: new BigNumber(0),
            signature: '0x0',
        } as unknown as OrderIntent;
        setOrderIntent(orderIntent);
        return orderIntent;
    };

    const placeOrder = async (orderIntent: OrderIntent) => {
        if (isOneCLickTradingEnabled || disableOneClickTradingPrompt) {
            dispatch(PLACE_ORDER_INTENT.request(orderIntent));
        } else {
            dispatch(
                SET_ONE_CLICK_TRADING_INTENT_UI_STATE({
                    uiState: OneClickTradingIntentUIState.PENDING_WALLET_CONFIRMATION,
                    orderIntent,
                }),
            );
        }

        setPrice('');
        setAmount('');
        setLeverage(0);
        setUserAlreadyTouched(false);
    };

    const placeModifyOrder = async (orderIntent: OrderIntent) => {
        if (isOneCLickTradingEnabled || disableOneClickTradingPrompt) {
            try {
                dispatch(
                    MODIFY_ORDER_INTENT.request({
                        traderAddress: selectedOrder?.traderAddress,
                        symbol: orderIntent.symbol,
                        strategy: orderIntent.strategy,
                        side: orderIntent.side,
                        orderType: orderIntent.orderType,
                        nonce: orderIntent.nonce,
                        amount: orderIntent.amount,
                        price: orderIntent.price,
                        stopPrice: orderIntent.stopPrice,
                        signature: orderIntent.signature,
                        orderHash: selectedOrder?.orderHash,
                        sessionKeySignature: selectedOrder?.sessionKeySignature,
                    } as ModifyOrderIntent),
                );
            } catch (e) {
                getFrontendLogger().log(getErrorMessage(e));
            }
        } else {
            dispatch(
                SET_ONE_CLICK_TRADING_INTENT_UI_STATE({
                    uiState: OneClickTradingIntentUIState.PENDING_WALLET_CONFIRMATION,
                    orderIntent,
                    isModifyOrder: true,
                }),
            );
        }

        setPrice('');
        setAmount('');
        setLeverage(0);
        setUserAlreadyTouched(false);
    };

    return (
        <>
            <Button
                size={isDesktopView ? 'md' : 'sm'}
                color="white"
                width="full"
                id={isModifyOrder ? 'confirm-modify-order-button' : 'confirm-order-button'}
                data-cy={isModifyOrder ? 'confirm-modify-order-button' : 'confirm-order-button'}
                colorScheme={orderSide === OrderSide.Bid ? 'brand.green' : 'brand.red'}
                isDisabled={
                    !isConnected ||
                    orderTypeLimitIsEmpty ||
                    leverageState === Intent.DANGER ||
                    new BigNumber(currentStrategyAvailableCollateral || 0).isZero() ||
                    new BigNumber(price !== '' ? price : 0)
                        .multipliedBy(parseFloat(amount))
                        .isGreaterThanOrEqualTo(1000000) ||
                    // ignore the omf < imf check when order will be reducing leverage
                    checkOmfLessImf ||
                    (isModifyOrder && !isValidModifyOrder)
                }
                onClick={async () => {
                    const orderIntent = await createOrder(orderSide, orderType);
                    if (orderIntent === undefined) {
                        return;
                    }
                    if (isModifyOrder) {
                        await placeModifyOrder(orderIntent);
                    } else {
                        await placeOrder(orderIntent);
                    }
                }}
            >
                {getConfirmButtonLabel()}
            </Button>
            {orderIntent !== undefined ? (
                isModifyOrder ? (
                    <SubmitModifyOrder
                        state={modifyOrderUiState}
                        tryAgain={() => placeModifyOrder(orderIntent)}
                        onClose={() => {
                            dispatch(SET_MODIFY_ORDER_UI_STATE(ModifyOrderUIState.NONE));
                            setOrderIntent(undefined);
                        }}
                        orderIntent={orderIntent}
                    />
                ) : (
                    <SubmitOrder
                        state={placeOrderUiState}
                        tryAgain={() => placeOrder(orderIntent)}
                        onClose={() => {
                            dispatch(SET_PLACE_ORDER_UI_STATE(PlaceOrderUIState.NONE));
                            setOrderIntent(undefined);
                        }}
                        orderIntent={orderIntent}
                    />
                )
            ) : (
                <></>
            )}
        </>
    );
}
