import { BigNumber } from '@0x/utils';
import {
    ActionType,
    FeedEventType,
    Jsonify,
    LiquidationEvent,
    OrderIntent,
    OrderSide,
    PnlRealizationEvent,
    ToastUIState,
    UIDowntimeCode,
    UIDowntimeState,
    UIDowntimeSubcode,
    UIToast,
    UIToastActionID,
} from '@derivadex/types';
import { ALERTS_CACHE, LATEST_ALERTS_READ } from '@derivadex/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAction } from 'typesafe-actions';

export type LiquidationAlertEvent = {
    c: { epoch: number; txOrdinal: number; value: BigNumber; createdAt: Date; symbol: string };
    t: 'LiquidationAlert';
    e: FeedEventType.UPDATE;
    r: undefined;
};
export type AlertNotification = (LiquidationEvent | PnlRealizationEvent | LiquidationAlertEvent) & { read: boolean };

export enum CancelUIState {
    NONE,
    PENDING_CONFIRMATION,
    IN_PROGRESS,
}

export enum SubmitCheckpointUIState {
    NONE,
    PENDING_CONFIRMATION,
}

export enum ClosePositionUIState {
    NONE,
    PENDING_USER_CONFIRMATION,
    PENDING_WALLET_CONFIRMATION,
    PENDING_TRANSACTION_CONFIRMATIONS,
    WALLET_REJECTED,
    TRANSACTION_EXECUTION_FAILED,
    TRANSACTION_VALIDATION_FAILED,
    TRANSACTION_CONFIRMED,
}

export enum ToggleUsdcUIState {
    NONE,
    PENDING_WALLET_CONFIRMATION,
}

export enum ToggleDdxUIState {
    NONE,
    PENDING_WALLET_CONFIRMATION,
}

export enum ProfileIntentUIState {
    NONE,
    PENDING_WALLET_CONFIRMATION,
}

export enum OneClickTradingIntentUIState {
    NONE,
    PENDING_WALLET_CONFIRMATION,
}

export enum DepositUsdcUIState {
    NONE,
    PENDING_WALLET_CONFIRMATION,
    PENDING_TRANSACTION_CONFIRMATIONS,
    CONFIRMED,
}

export enum WithdrawIntentUsdcUIState {
    NONE,
    PENDING_WALLET_CONFIRMATION,
    IN_PROGRESS,
}

export enum WithdrawUsdcUIState {
    NONE,
    PENDING_CONFIRMATION,
}

export enum DepositDdxUIState {
    NONE,
    PENDING_WALLET_CONFIRMATION,
    PENDING_TRANSACTION_CONFIRMATIONS,
    CONFIRMED,
}

export enum WithdrawIntentDdxUIState {
    NONE,
    PENDING_WALLET_CONFIRMATION,
    IN_PROGRESS,
}

export enum WithdrawDdxUIState {
    NONE,
    PENDING_CONFIRMATION,
}

export enum PlaceOrderUIState {
    NONE,
    PENDING_WALLET_CONFIRMATION,
    PENDING_TRANSACTION_CONFIRMATIONS,
    WALLET_REJECTED,
    TRANSACTION_EXECUTION_FAILED,
    TRANSACTION_VALIDATION_FAILED,
    TRANSACTION_CONFIRMED,
}

export enum ModifyOrderUIState {
    NONE,
    PENDING_WALLET_CONFIRMATION,
    PENDING_TRANSACTION_CONFIRMATIONS,
    WALLET_REJECTED,
    TRANSACTION_EXECUTION_FAILED,
    TRANSACTION_VALIDATION_FAILED,
    TRANSACTION_CONFIRMED,
}

export enum UIViewState {
    MARKETPLACE,
    PORTFOLIO,
    VAULTS,
    LEADERBOARD,
    FUNDING_COMPARISON,
}

export type OneClickTradingOptInUIData = {
    uiState: OneClickTradingIntentUIState;
    orderIntent?: OrderIntent;
    isModifyOrder?: boolean;
};

export type UIState = {
    isNode0Healthy: boolean;
    isDepositDialogActive: boolean;
    isWithdrawTokensDialogActive: boolean;
    isWithdrawDdxDialogActive: boolean;
    isWalletDialogActive: boolean;
    isConsentDialogActive: boolean;
    isMarketsDialogActive: boolean;
    isStrategiesDialogActive: boolean;
    isAlertsDialogActive: boolean;
    isOmfLessImf: boolean;
    cancel: CancelUIState;
    checkpoint: SubmitCheckpointUIState;
    closePosition: ClosePositionUIState;
    depositUsdc: DepositUsdcUIState;
    depositDdx: DepositDdxUIState;
    withdrawIntentUsdc: WithdrawIntentUsdcUIState;
    withdrawUsdc: WithdrawUsdcUIState;
    withdrawIntentDdx: WithdrawIntentDdxUIState;
    withdrawDdx: WithdrawDdxUIState;
    toggleUsdc: ToggleUsdcUIState;
    toggleDdx: ToggleDdxUIState;
    profileIntent: ProfileIntentUIState;
    oneClickTradingOptInData: OneClickTradingOptInUIData;
    placeOrder: PlaceOrderUIState;
    modifyOrder: ModifyOrderUIState;
    toasts: ToastUIState;
    orderbookPriceSelected: BigNumber | undefined;
    downtime: boolean;
    downtimeMessage: UIDowntimeState;
    downtimeCode: UIDowntimeCode;
    downtimeSubcode: UIDowntimeSubcode | undefined;
    view: UIViewState;
    alerts: AlertNotification[];
};

export const initialUIState: UIState = {
    isNode0Healthy: true,
    isDepositDialogActive: false,
    isWithdrawTokensDialogActive: false,
    isWithdrawDdxDialogActive: false,
    isWalletDialogActive: false,
    isConsentDialogActive: false,
    isMarketsDialogActive: false,
    isStrategiesDialogActive: false,
    isAlertsDialogActive: false,
    isOmfLessImf: false,
    cancel: CancelUIState.NONE,
    checkpoint: SubmitCheckpointUIState.NONE,
    closePosition: ClosePositionUIState.NONE,
    depositUsdc: DepositUsdcUIState.NONE,
    depositDdx: DepositDdxUIState.NONE,
    withdrawIntentUsdc: WithdrawIntentUsdcUIState.NONE,
    withdrawUsdc: WithdrawUsdcUIState.NONE,
    withdrawIntentDdx: WithdrawIntentDdxUIState.NONE,
    withdrawDdx: WithdrawDdxUIState.NONE,
    toggleUsdc: ToggleUsdcUIState.NONE,
    toggleDdx: ToggleDdxUIState.NONE,
    profileIntent: ProfileIntentUIState.NONE,
    oneClickTradingOptInData: { uiState: OneClickTradingIntentUIState.NONE, orderIntent: undefined },
    placeOrder: PlaceOrderUIState.NONE,
    modifyOrder: ModifyOrderUIState.NONE,
    toasts: { activeToast: undefined, toastQueue: [], pendingToasts: {} },
    orderbookPriceSelected: undefined,
    downtime: false,
    downtimeMessage: UIDowntimeState.NONE,
    downtimeCode: UIDowntimeCode.NONE,
    downtimeSubcode: undefined,
    view: UIViewState.MARKETPLACE,
    alerts: [],
};

export const uiSlice = createSlice({
    name: 'ui',
    initialState: initialUIState,
    reducers: {
        TOGGLE_DEPOSIT_COLLATERALS_DIALOG: (state) => {
            state.isDepositDialogActive = !state.isDepositDialogActive;
        },
        TOGGLE_WITHDRAW_TOKENS_DIALOG: (state) => {
            state.isWithdrawTokensDialogActive = !state.isWithdrawTokensDialogActive;
        },
        TOGGLE_WITHDRAW_DDX_DIALOG: (state) => {
            state.isWithdrawDdxDialogActive = !state.isWithdrawDdxDialogActive;
        },
        TOGGLE_WALLET_DIALOG: (state) => {
            state.isWalletDialogActive = !state.isWalletDialogActive;
        },
        TOGGLE_CONSENT_DIALOG: (state) => {
            state.isConsentDialogActive = !state.isConsentDialogActive;
        },
        TOGGLE_MARKETS_DIALOG: (state) => {
            state.isMarketsDialogActive = !state.isMarketsDialogActive;
        },
        TOGGLE_STRATEGIES_DIALOG: (state) => {
            state.isStrategiesDialogActive = !state.isStrategiesDialogActive;
        },
        TOGGLE_ALERTS_DIALOG: (state) => {
            state.isAlertsDialogActive = !state.isAlertsDialogActive;
        },
        SET_IS_OMF_LESS_IMF: (state, action: PayloadAction<boolean>) => {
            state.isOmfLessImf = action.payload;
        },
        SET_CANCEL_UI_STATE: (state, action: PayloadAction<CancelUIState>) => {
            state.cancel = action.payload;
        },
        SET_SUBMIT_CHECKPOINT_UI_STATE: (state, action: PayloadAction<SubmitCheckpointUIState>) => {
            state.checkpoint = action.payload;
        },
        SET_CLOSE_POSITION_UI_STATE: (state, action: PayloadAction<ClosePositionUIState>) => {
            state.closePosition = action.payload;
        },
        SET_DEPOSIT_USDC_UI_STATE: (state, action: PayloadAction<DepositUsdcUIState>) => {
            state.depositUsdc = action.payload;
        },
        SET_DEPOSIT_DDX_UI_STATE: (state, action: PayloadAction<DepositDdxUIState>) => {
            state.depositDdx = action.payload;
        },
        SET_WITHDRAW_INTENT_USDC_UI_STATE: (state, action: PayloadAction<WithdrawIntentUsdcUIState>) => {
            state.withdrawIntentUsdc = action.payload;
        },
        SET_WITHDRAW_USDC_UI_STATE: (state, action: PayloadAction<WithdrawUsdcUIState>) => {
            state.withdrawUsdc = action.payload;
        },
        SET_WITHDRAW_INTENT_DDX_UI_STATE: (state, action: PayloadAction<WithdrawIntentDdxUIState>) => {
            state.withdrawIntentDdx = action.payload;
        },
        SET_WITHDRAW_DDX_UI_STATE: (state, action: PayloadAction<WithdrawDdxUIState>) => {
            state.withdrawDdx = action.payload;
        },
        SET_TOGGLE_USDC_UI_STATE: (state, action: PayloadAction<ToggleUsdcUIState>) => {
            state.toggleUsdc = action.payload;
        },
        SET_TOGGLE_DDX_UI_STATE: (state, action: PayloadAction<ToggleDdxUIState>) => {
            state.toggleDdx = action.payload;
        },
        SET_PROFILE_INTENT_UI_STATE: (state, action: PayloadAction<ProfileIntentUIState>) => {
            state.profileIntent = action.payload;
        },
        SET_ONE_CLICK_TRADING_INTENT_UI_STATE: (state, action: PayloadAction<OneClickTradingOptInUIData>) => {
            state.oneClickTradingOptInData = action.payload;
        },
        SET_PLACE_ORDER_UI_STATE: (state, action: PayloadAction<PlaceOrderUIState>) => {
            state.placeOrder = action.payload;
        },
        SET_MODIFY_ORDER_UI_STATE: (state, action: PayloadAction<ModifyOrderUIState>) => {
            state.modifyOrder = action.payload;
        },
        ADD_TOAST_MESSAGE: (state, action: PayloadAction<UIToast>) => {
            state.toasts.toastQueue = state.toasts.toastQueue.concat(action.payload);
        },
        ADD_PENDING_TOAST: (state, action: PayloadAction<{ actionID: UIToastActionID; id: string }>) => {
            const shallow: { [actionID: string]: string } = { ...state.toasts.pendingToasts };
            shallow[action.payload.actionID] = action.payload.id;
            state.toasts.pendingToasts = shallow;
        },
        REMOVE_PENDING_TOAST: (
            state,
            action: PayloadAction<{
                actionID: UIToastActionID;
            }>,
        ) => {
            const actionID = action.payload.actionID;
            const { [actionID]: value, ...rest } = state.toasts.pendingToasts;
            state.toasts.pendingToasts = rest;
        },
        PROCESS_TOAST_MESSAGE: (state) => {
            [state.toasts.activeToast, ...state.toasts.toastQueue] = state.toasts.toastQueue;
        },
        CLEAR_ACTIVE_TOAST_MESSAGE: (state) => {
            state.toasts.activeToast = undefined;
        },
        SET_SELECTED_ORDERBOOK_PRICE: (state, action: PayloadAction<BigNumber>) => {
            state.orderbookPriceSelected = action.payload;
        },
        SET_DOWNTIME: (
            state,
            action: PayloadAction<{ message: UIDowntimeState; code: UIDowntimeCode; subcode?: UIDowntimeSubcode }>,
        ) => {
            state.downtime = true;
            state.downtimeMessage = action.payload.message;
            state.downtimeCode = action.payload.code;
            state.downtimeSubcode = action.payload.subcode;
        },
        UPDATE_CURRENT_VIEW: (
            state,
            action: PayloadAction<{
                view: UIViewState;
            }>,
        ) => {
            state.view = action.payload.view;
        },
        SET_IS_NODE0_HEALTHY: (state, action: PayloadAction<boolean>) => {
            state.isNode0Healthy = action.payload;
        },
        ADD_ALERT_NOTIFICATION: (
            state,
            action: PayloadAction<{ notification: LiquidationEvent | PnlRealizationEvent; trader: string }>,
        ) => {
            let alerts = state.alerts;
            if (alerts.length === 0) {
                alerts = buildAlertsFromCache(
                    window.localStorage.getItem(ALERTS_CACHE + `_${action.payload.trader.toLowerCase()}`),
                );
            }
            const local = window.localStorage.getItem(LATEST_ALERTS_READ + `_${action.payload.trader.toLowerCase()}`);
            const value = action.payload.notification.c;
            const found = alerts.find((it) => it.c.epoch === value.epoch && it.c.txOrdinal === value.txOrdinal);
            // Discard if already found (possible due to caching on local storage)
            if (!found) {
                if (local !== null) {
                    const parsed: { epoch: number; txOrdinal: number } = JSON.parse(local);
                    if (value.epoch > parsed.epoch) {
                        state.alerts = [{ ...action.payload.notification, read: false }, ...state.alerts];
                        window.localStorage.setItem(
                            ALERTS_CACHE + `_${action.payload.trader.toLowerCase()}`,
                            JSON.stringify(state.alerts),
                        );
                    } else if (value.epoch === parsed.epoch && value.txOrdinal > parsed.txOrdinal) {
                        state.alerts = [{ ...action.payload.notification, read: false }, ...state.alerts];
                        window.localStorage.setItem(
                            ALERTS_CACHE + `_${action.payload.trader.toLowerCase()}`,
                            JSON.stringify(state.alerts),
                        );
                    }
                } else {
                    state.alerts = [{ ...action.payload.notification, read: false }, ...state.alerts];
                    window.localStorage.setItem(
                        ALERTS_CACHE + `_${action.payload.trader.toLowerCase()}`,
                        JSON.stringify(state.alerts),
                    );
                }
            }
        },
        ADD_LIQUIDATION_ALERT_NOTIFICATION: (
            state,
            action: PayloadAction<{ notification: LiquidationAlertEvent; trader: string }>,
        ) => {
            let alerts = state.alerts;
            if (alerts.length === 0) {
                alerts = buildAlertsFromCache(
                    window.localStorage.getItem(ALERTS_CACHE + `_${action.payload.trader.toLowerCase()}`),
                );
            }
            const value = action.payload.notification.c;
            const found = alerts.find((it) => it.t === 'LiquidationAlert' && it.c.symbol === value.symbol);
            // Discard if already found (possible due to caching on local storage)
            if (!found) {
                state.alerts = [{ ...action.payload.notification, read: false }, ...state.alerts];
                window.localStorage.setItem(
                    ALERTS_CACHE + `_${action.payload.trader.toLowerCase()}`,
                    JSON.stringify(state.alerts),
                );
            }
        },
        SET_ALERT_NOTIFICATIONS_AS_ALL_READ: (state, action: PayloadAction<string>) => {
            const filtered = state.alerts.filter((it) => it.t !== 'LiquidationAlert');
            let keepSubset;
            if (state.alerts.length > 25) {
                keepSubset = state.alerts.slice(-25).map((it) => {
                    it.read = true;
                    return it;
                });
            } else {
                keepSubset = state.alerts.map((it) => {
                    it.read = true;
                    return it;
                });
            }
            window.localStorage.setItem(ALERTS_CACHE + `_${action.payload.toLowerCase()}`, JSON.stringify(keepSubset));
            if (filtered.length > 0) {
                const latest = filtered[0];
                window.localStorage.setItem(
                    LATEST_ALERTS_READ + `_${action.payload.toLowerCase()}`,
                    JSON.stringify({ epoch: latest.c.epoch, txOrdinal: latest.c.txOrdinal }),
                );
            }
            state.alerts = keepSubset;
        },
        ALERT_NOTIFICATIONS_CLEAR_ALL: (state, action: PayloadAction<string>) => {
            const filtered = state.alerts.filter((it) => it.t !== 'LiquidationAlert');
            window.localStorage.setItem(ALERTS_CACHE + `_${action.payload.toLowerCase()}`, JSON.stringify([]));
            if (filtered.length > 0) {
                const latest = filtered[0];
                window.localStorage.setItem(
                    LATEST_ALERTS_READ + `_${action.payload.toLowerCase()}`,
                    JSON.stringify({ epoch: latest.c.epoch, txOrdinal: latest.c.txOrdinal }),
                );
            }
            state.alerts = [];
        },
        ALERT_STATE_RESET: (state, action: PayloadAction<string>) => {
            state.alerts = buildAlertsFromCache(
                window.localStorage.getItem(ALERTS_CACHE + `_${action.payload.toLowerCase()}`),
            );
        },
    },
});

export default uiSlice.reducer;

export const {
    TOGGLE_DEPOSIT_COLLATERALS_DIALOG,
    TOGGLE_WITHDRAW_TOKENS_DIALOG,
    TOGGLE_WITHDRAW_DDX_DIALOG,
    TOGGLE_WALLET_DIALOG,
    TOGGLE_CONSENT_DIALOG,
    TOGGLE_MARKETS_DIALOG,
    TOGGLE_STRATEGIES_DIALOG,
    TOGGLE_ALERTS_DIALOG,
    SET_CANCEL_UI_STATE,
    SET_SUBMIT_CHECKPOINT_UI_STATE,
    SET_CLOSE_POSITION_UI_STATE,
    SET_DEPOSIT_USDC_UI_STATE,
    SET_DEPOSIT_DDX_UI_STATE,
    SET_WITHDRAW_INTENT_USDC_UI_STATE,
    SET_WITHDRAW_USDC_UI_STATE,
    SET_WITHDRAW_INTENT_DDX_UI_STATE,
    SET_WITHDRAW_DDX_UI_STATE,
    SET_TOGGLE_USDC_UI_STATE,
    SET_TOGGLE_DDX_UI_STATE,
    SET_PROFILE_INTENT_UI_STATE,
    SET_ONE_CLICK_TRADING_INTENT_UI_STATE,
    SET_PLACE_ORDER_UI_STATE,
    SET_MODIFY_ORDER_UI_STATE,
    ADD_TOAST_MESSAGE,
    ADD_PENDING_TOAST,
    REMOVE_PENDING_TOAST,
    PROCESS_TOAST_MESSAGE,
    CLEAR_ACTIVE_TOAST_MESSAGE,
    SET_SELECTED_ORDERBOOK_PRICE,
    SET_DOWNTIME,
    UPDATE_CURRENT_VIEW,
    SET_IS_NODE0_HEALTHY,
    SET_IS_OMF_LESS_IMF,
    ADD_ALERT_NOTIFICATION,
    ADD_LIQUIDATION_ALERT_NOTIFICATION,
    SET_ALERT_NOTIFICATIONS_AS_ALL_READ,
    ALERT_NOTIFICATIONS_CLEAR_ALL,
    ALERT_STATE_RESET,
} = uiSlice.actions;

export const UPDATE_OMF_LESS_IMF = createAction(ActionType.UPDATE_OMF_LESS_IMF)<{
    side: OrderSide;
    size: BigNumber;
    remainingAmount: BigNumber;
    price: BigNumber;
    symbol: string;
}>();

export function buildAlertsFromCache(cache: string | null): AlertNotification[] {
    const parsed: Jsonify<AlertNotification[]> = JSON.parse(cache || '[]') as Jsonify<AlertNotification[]>;
    const alerts = parsed.map((it) => {
        if (it.t === 'LiquidationAlert') {
            const alert = {
                ...it,
                c: { ...it.c, createdAt: new Date(it.c.createdAt), value: new BigNumber(it.c.value) },
            };
            return alert;
        } else {
            const alert = { ...it, c: { ...it.c, createdAt: new Date(it.c.createdAt) } };
            return alert;
        }
    });
    return alerts.sort((a, b) => a.c.createdAt.getTime() - b.c.createdAt.getTime());
}
