import { Box, Flex, Text, useTheme } from '@chakra-ui/react';
import { ParamOrderSide, ParamOrderType, StatsAPISuccessTimeseriesResponse, TradeResponseItem } from '@derivadex/types';
import { createColumnHelper } from '@tanstack/react-table';
import CryptoIcon from 'components/CryptoIcon/CryptoIcon';
import ExplorerButton from 'components/Marketplace/OrderTables/ExplorerButton';
import NumberFormatMinimumValue from 'components/Marketplace/OrderTables/NumberFormatMinimumValue';
import Table from 'components/Table/Table';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { getTradesHistoryCurrentPage, getTradesHistoryData } from 'store/portfolio/selectors';
import { FETCH_TRADES_HISTORY_DATA } from 'store/portfolio/slice';
import { FetchDataState } from 'store/requestUtils';
import { getSelectedStrategy } from 'store/strategy/selectors';
import { getEthAddress } from 'store/web3/selectors';
import { sortBigNumber } from 'utils/sortType';

import TradesTableHeader from '../Header/TradesTableHeader';

export default function TradesTable({ isDesktopView }: { isDesktopView: boolean }) {
    const dispatch = useDispatch();
    const currentStrategy = useSelector(getSelectedStrategy);
    const traderAddress = useSelector(getEthAddress);
    const { t } = useTranslation();
    const { colors } = useTheme();
    const data: StatsAPISuccessTimeseriesResponse<TradeResponseItem> | undefined = useSelector(getTradesHistoryData);
    const currentPage: number = useSelector(getTradesHistoryCurrentPage);

    useEffect(() => {
        if (!currentStrategy || !traderAddress || data !== undefined || currentPage !== 0) {
            return;
        }
        dispatch(FETCH_TRADES_HISTORY_DATA(FetchDataState.NextPage));
    }, [currentStrategy, traderAddress]);

    const nextPage = () => {
        dispatch(FETCH_TRADES_HISTORY_DATA(FetchDataState.NextPage));
    };

    const prevPage = () => {
        dispatch(FETCH_TRADES_HISTORY_DATA(FetchDataState.PrevPage));
    };

    const columnHelper = createColumnHelper<TradeResponseItem>();
    const columns = [
        columnHelper.accessor('symbol', {
            cell: (info) => (
                <Flex align="center">
                    <CryptoIcon currency={getCryptoIcon(info.getValue())} size={4} />
                    <Text ml="0.5rem" fontWeight="600">
                        {info.getValue()}
                    </Text>
                </Flex>
            ),
            header: t('symbol'),
            meta: {
                style: !isDesktopView
                    ? {
                          position: 'sticky',
                          left: 0,
                          zIndex: 10,
                          backgroundColor: `${colors.card[100]}`,
                      }
                    : null,
            },
        }),
        columnHelper.accessor('orderType', {
            cell: (info) => (
                <Text>{Object.keys(ParamOrderType)[Object.values(ParamOrderType).indexOf(info.getValue())]}</Text>
            ),
            header: t('type'),
        }),
        columnHelper.accessor('side', {
            cell: (info) => (
                <Text>{Object.keys(ParamOrderSide)[Object.values(ParamOrderSide).indexOf(info.getValue())]}</Text>
            ),
            header: t('side'),
        }),
        columnHelper.accessor('createdAt', {
            cell: (info) => format(info.getValue(), 'MM/dd/yy, pp'),
            header: t('filledOn'),
        }),
        columnHelper.accessor('price', {
            cell: (info) => (
                <NumericFormat
                    value={info.getValue()?.toNumber()}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale
                    decimalScale={2}
                />
            ),
            header: t('price'),
            meta: {
                isNumeric: true,
            },
            sortingFn: sortBigNumber,
        }),
        columnHelper.accessor('amount', {
            cell: (info) => (
                <NumericFormat
                    value={info.getValue().toNumber()}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale
                    decimalScale={5}
                />
            ),
            header: t('amount'),
            meta: {
                isNumeric: true,
            },
            sortingFn: sortBigNumber,
        }),
        columnHelper.accessor((row) => row.price?.multipliedBy(row.amount), {
            cell: (info) => <NumberFormatMinimumValue value={info.getValue() || null} />,
            header: t('notional'),
            meta: {
                isNumeric: true,
            },
        }),
        columnHelper.accessor('feeSymbol', {
            cell: (info) => <Text>{info.getValue()}</Text>,
            header: t('feeSymbol'),
        }),
        columnHelper.accessor('fee', {
            cell: (info) => <NumberFormatMinimumValue value={info.getValue()} />,
            header: t('fee'),
            meta: {
                isNumeric: true,
            },
            sortingFn: sortBigNumber,
        }),
        columnHelper.accessor((row) => row, {
            cell: (info) => (
                <Flex marginLeft={'1rem'}>
                    <ExplorerButton
                        label={t('orderExplorerButtonDescription')}
                        url={`epoch/${info.getValue().epochId}/txOrdinal/${info.getValue().txOrdinal}`}
                    />
                </Flex>
            ),
            header: ' ',
        }),
    ];

    function getCryptoIcon(symbol: string | undefined) {
        if (symbol === 'BTCP') return 'BTC';
        return 'ETH';
    }

    return (
        <Box p="0.5rem" className={data?.success && data.value.length > 0 ? 'table-trades' : 'table-trades-empty'}>
            <TradesTableHeader />
            <Table
                isDesktopView={isDesktopView}
                columns={columns}
                data={data?.success && data.value.length > 0 ? data.value : []}
                emptyText={t('noData')}
                sortBy={[{ id: 'createdAt', desc: true }]}
                columnVisibility={{ createdAt: false }}
                paginationData={{ isEnabled: true, currentPage, nextPage, prevPage }}
            />
        </Box>
    );
}
