import { BigNumber } from '@0x/utils';
import {
    ActionType,
    EnrollmentStatus,
    OneClickTradingStorageData,
    ParamTraderUpdateKind,
    RequestFailure,
    RequestSequenced,
    Ticker,
    Trader,
    UpdateFavoriteTickersIntent,
    UpdateOneClickTradingIntent,
    UpdateProfileIntent,
    WithdrawDDXIntent,
} from '@derivadex/types';
import {
    DISABLE_ORDER_NOTIFICATIONS,
    DISABLE_PLAY_SOUND_FOR_FILLS,
    ENABLE_LIQUIDATION_PRICE_ALERT,
    FAVORITE_TICKER_DATA,
    ONE_CLICK_TRADING_DATA,
    SKIP_CANCEL_CLOSE_POSITION_CONFIRMATION,
} from '@derivadex/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'typesafe-actions';

export type ProfileState = {
    availDDX: BigNumber;
    lockedDDX: BigNumber;
    estimatedRewards: BigNumber;
    payFeesInDDX: boolean;
    isDefault: boolean; // true when trader isn't found in DB
    isPayFeesInDDXLoading: boolean;
    epochId: BigNumber;
    txOrdinal: BigNumber;
    latestUpdate:
        | {
              amount: BigNumber;
              type: ParamTraderUpdateKind;
          }
        | undefined;
    enrollmentStatus: EnrollmentStatus;
    oneClickTradingEnabled: boolean;
    disableOneClickTradingPrompt: boolean;
    disableOrderNotifications: boolean;
    skipCancelClosePositionConfirmation: boolean;
    enableLiquidationPriceAlert: boolean;
    disablePlaySoundForFills: boolean;
    oneClickTradingData: OneClickTradingStorageData | undefined;
    favoriteTickers: Ticker[];
};

export const initialProfileState: ProfileState = {
    availDDX: new BigNumber(0),
    lockedDDX: new BigNumber(0),
    payFeesInDDX: false,
    isDefault: true,
    isPayFeesInDDXLoading: false,
    estimatedRewards: new BigNumber(0),
    epochId: new BigNumber(0),
    txOrdinal: new BigNumber(0),
    latestUpdate: undefined,
    enrollmentStatus: EnrollmentStatus.NONE,
    oneClickTradingEnabled: false,
    disableOneClickTradingPrompt: false,
    disableOrderNotifications: false,
    skipCancelClosePositionConfirmation: false,
    enableLiquidationPriceAlert: false,
    disablePlaySoundForFills: false,
    oneClickTradingData: undefined,
    favoriteTickers: [],
};

export const profileSlice = createSlice({
    name: 'profile',
    initialState: initialProfileState,
    reducers: {
        SET_PROFILE: (state, action: PayloadAction<Trader>) => {
            state.availDDX = action.payload.availDDX;
            state.lockedDDX = action.payload.lockedDDX;
            state.payFeesInDDX = action.payload.payFeesInDDX;
            state.isDefault = action.payload.isDefault;
            state.epochId = action.payload.epochId;
            state.txOrdinal = action.payload.txOrdinal;
            if (action.payload.latestUpdate) {
                state.latestUpdate = action.payload.latestUpdate;
            }
            const serializedOneClickTradingDataMap = window.localStorage.getItem(ONE_CLICK_TRADING_DATA);
            if (serializedOneClickTradingDataMap !== null) {
                const deserializedMap = new Map<string, OneClickTradingStorageData>(
                    Object.entries(JSON.parse(serializedOneClickTradingDataMap)),
                );
                const data = deserializedMap.get(action.payload.trader);
                if (data) {
                    state.oneClickTradingEnabled = data.enabled;
                    state.oneClickTradingData = data;
                }
            }
            const optOutFlag = window.localStorage.getItem('disableOneClickTradingPrompt');
            if (optOutFlag) {
                state.disableOneClickTradingPrompt = optOutFlag === 'true' ? true : false;
            }

            const serializedTickerData = window.localStorage.getItem(FAVORITE_TICKER_DATA);
            if (serializedTickerData !== null) {
                const deserializedFavoriteTickers: Ticker[] = JSON.parse(serializedTickerData);
                if (deserializedFavoriteTickers) {
                    state.favoriteTickers = deserializedFavoriteTickers;
                }
            }

            state.disableOrderNotifications =
                window.localStorage.getItem(DISABLE_ORDER_NOTIFICATIONS) === 'true' ? true : false;

            state.skipCancelClosePositionConfirmation =
                window.localStorage.getItem(SKIP_CANCEL_CLOSE_POSITION_CONFIRMATION) === 'true' ? true : false;

            state.enableLiquidationPriceAlert =
                window.localStorage.getItem(ENABLE_LIQUIDATION_PRICE_ALERT) === 'true' ? true : false;

            state.disablePlaySoundForFills =
                window.localStorage.getItem(DISABLE_PLAY_SOUND_FOR_FILLS) === 'true' ? true : false;
        },
        SET_IS_PAY_FEES_IN_DDX_LOADING: (state, action: PayloadAction<boolean>) => {
            state.isPayFeesInDDXLoading = action.payload;
        },
        SET_ESTIMATED_REWARDS: (state, action: PayloadAction<BigNumber>) => {
            state.estimatedRewards = action.payload;
        },
        UPDATE_ENROLLMENT_STATUS: (state, action: PayloadAction<EnrollmentStatus>) => {
            state.enrollmentStatus = action.payload;
        },
        UPDATE_ONE_CLICK_TRADING_STATUS: (state, action: PayloadAction<{ data: OneClickTradingStorageData }>) => {
            const data = action.payload.data;
            const serializedOneClickTradingDataMap = window.localStorage.getItem(ONE_CLICK_TRADING_DATA);
            let deserializedMap: Map<string, OneClickTradingStorageData> | undefined = undefined;
            if (serializedOneClickTradingDataMap !== null) {
                deserializedMap = new Map<string, OneClickTradingStorageData>(
                    Object.entries(JSON.parse(serializedOneClickTradingDataMap)),
                );
            } else {
                deserializedMap = new Map<string, OneClickTradingStorageData>();
            }
            deserializedMap.set(data.trader, {
                trader: data.trader,
                enabled: data.enabled,
                signature: data.signature,
                sessionPrivateKey: data.sessionPrivateKey,
            } as OneClickTradingStorageData);
            window.localStorage.setItem(
                ONE_CLICK_TRADING_DATA,
                JSON.stringify(Object.fromEntries(deserializedMap.entries())),
            );
            state.oneClickTradingEnabled = data.enabled;
            state.oneClickTradingData = data;
        },
        UPDATE_FAVORITE_TICKERS: (state, action: PayloadAction<{ ticker: Ticker; include: boolean }>) => {
            const serializedTickerData = window.localStorage.getItem(FAVORITE_TICKER_DATA);
            let updatedFavoriteTickerArray: Ticker[] = [];
            if (serializedTickerData !== null) {
                const deserializedFavoriteTickers: Ticker[] = JSON.parse(serializedTickerData);
                if (deserializedFavoriteTickers) {
                    if (action.payload.include) {
                        updatedFavoriteTickerArray = [...deserializedFavoriteTickers, action.payload.ticker];
                    } else {
                        updatedFavoriteTickerArray = deserializedFavoriteTickers.filter(
                            (it) => it.symbol !== action.payload.ticker.symbol,
                        );
                    }
                }
            } else {
                updatedFavoriteTickerArray = [action.payload.ticker];
            }
            state.favoriteTickers = updatedFavoriteTickerArray;
            window.localStorage.setItem(FAVORITE_TICKER_DATA, JSON.stringify(updatedFavoriteTickerArray));
        },
        SET_DISABLE_ONE_CLICK_TRADING_PROMPT: (state, action: PayloadAction<boolean>) => {
            state.disableOneClickTradingPrompt = action.payload;
        },
        UPDATE_DISABLE_ORDER_NOTIFICATIONS: (state, action: PayloadAction<boolean>) => {
            state.disableOrderNotifications = action.payload;
            window.localStorage.setItem(DISABLE_ORDER_NOTIFICATIONS, action.payload.toString());
        },
        UPDATE_SKIP_CANCEL_CLOSE_POSITION_CONFIRMATION: (state, action: PayloadAction<boolean>) => {
            state.skipCancelClosePositionConfirmation = action.payload;
            window.localStorage.setItem(SKIP_CANCEL_CLOSE_POSITION_CONFIRMATION, action.payload.toString());
        },
        UPDATE_ENABLE_LIQUIDATION_PRICE_ALERT: (state, action: PayloadAction<boolean>) => {
            state.enableLiquidationPriceAlert = action.payload;
            window.localStorage.setItem(ENABLE_LIQUIDATION_PRICE_ALERT, action.payload.toString());
        },
        UPDATE_DISABLE_PLAY_SOUND_FOR_FILLS: (state, action: PayloadAction<boolean>) => {
            state.disablePlaySoundForFills = action.payload;
            window.localStorage.setItem(DISABLE_PLAY_SOUND_FOR_FILLS, action.payload.toString());
        },
    },
});

export const {
    SET_PROFILE,
    SET_ESTIMATED_REWARDS,
    SET_IS_PAY_FEES_IN_DDX_LOADING,
    UPDATE_ENROLLMENT_STATUS,
    UPDATE_ONE_CLICK_TRADING_STATUS,
    UPDATE_FAVORITE_TICKERS,
    UPDATE_DISABLE_ORDER_NOTIFICATIONS,
    UPDATE_SKIP_CANCEL_CLOSE_POSITION_CONFIRMATION,
    UPDATE_DISABLE_PLAY_SOUND_FOR_FILLS,
    SET_DISABLE_ONE_CLICK_TRADING_PROMPT,
    UPDATE_ENABLE_LIQUIDATION_PRICE_ALERT,
} = profileSlice.actions;

export const UPDATE_PROFILE_INTENT = createAsyncAction(
    ActionType.PROFILE_UPDATE_REQUEST,
    ActionType.PROFILE_UPDATE_SUCCESS,
    ActionType.PROFILE_UPDATE_FAILURE,
)<UpdateProfileIntent, RequestSequenced, RequestFailure>();

export const WITHDRAW_TRADER_INTENT = createAsyncAction(
    ActionType.WITHDRAW_DDX_REQUEST,
    ActionType.WITHDRAW_DDX_SUCCESS,
    ActionType.WITHDRAW_DDX_FAILURE,
)<WithdrawDDXIntent, RequestSequenced, RequestFailure>();

export const UPDATE_ONE_CLICK_TRADING_INTENT = createAsyncAction(
    ActionType.ONE_CLICK_TRADING_UPDATE_REQUEST,
    ActionType.ONE_CLICK_TRADING_UPDATE_SUCCESS,
    ActionType.ONE_CLICK_TRADING_UPDATE_FAILURE,
)<UpdateOneClickTradingIntent, RequestSequenced, RequestFailure>();

export const UPDATE_FAVORITE_TICKERS_INTENT = createAsyncAction(
    ActionType.UPDATE_FAVORITE_TICKERS_REQUEST,
    ActionType.UPDATE_FAVORITE_TICKERS_SUCCESS,
    ActionType.UPDATE_FAVORITE_TICKERS_FAILURE,
)<UpdateFavoriteTickersIntent, RequestSequenced, RequestFailure>();
